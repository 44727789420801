import {useState, useEffect} from 'react';

// hook to listen scrolling event in window and change the state
const useScrollWindow = (offset) => {

    // initial state to false and 
    const [ stateScrolling, updateState ] = useState(false);

    const handleScroll = (e) => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop;
        if( distanceY > offset ) {
            updateState(true);
        }
        else {
            updateState(false);
        }
    }

    useEffect( () => {
        window.addEventListener('scroll', handleScroll );

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    });

    return [stateScrolling];
}

export default useScrollWindow;


