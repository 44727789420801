/**
 * Get path to page type. The url can be extended dynamically with placeholders.
 *
 * @param pathType
 * @param data
 * @returns {{}}
 */
import getConfig from "next/config";
import { objectGet } from "../modules/user/lib/GeneralUtility";

const getPathConfig = (pathType, data, subPath = "", options = {}) => {
  const emptyResultOnRemainingPlaceholder =
    options?.emptyResultOnRemainingPlaceholder ?? true;

  const returnAbsoluteUrl = options?.absoluteUrl ?? false;
  const returnWithBasePath = options?.prependBasePath ?? false;

  let path = "";

  const { publicRuntimeConfig } = getConfig();
  const pagePaths = publicRuntimeConfig.pagePaths;

  const check = (typePath) => {
    const regex = /\{([\w\.]+)\}/g;
    return regex.exec(typePath);
  };

  if (pagePaths[pathType] || (subPath && pagePaths[pathType][subPath])) {
    let typePath =
      subPath && pagePaths[pathType][subPath]
        ? pagePaths[pathType][subPath]
        : pagePaths[pathType];

    if (typePath) {
      let placeholderMatch = "";
      while ((placeholderMatch = check(typePath))) {
        const placeholderName = placeholderMatch[1];
        const value = objectGet(data, placeholderName);
        if (value) {
          typePath = typePath.replace(`{${placeholderName}}`, value);
        } else if (emptyResultOnRemainingPlaceholder) {
          typePath = "";
          break;
        }
      }
      path = `${typePath}`;
    }
  }

  if (path !== "" && (returnWithBasePath || returnAbsoluteUrl)) {
    path = createLinkWithBasepath(path);
  }

  return returnAbsoluteUrl
    ? `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${path}`
    : path;
};

const getPathFromUrl = (url) => {
  return url.split(/[?#]/)[0];
};

const createLinkWithBasepath = (link) => {
  if (process.env.BASE_PATH && link && !link.startsWith("http")) {
    return `${process.env.BASE_PATH}${link}`;
  }
  return link;
};

const createPreviewUrl = (url) =>
  `/api/preview?slug=${url}&secret${process.env.CMS_PREVIEW_TOKEN}`;

export default {
  getPathConfig,
  getPathFromUrl,
  createLinkWithBasepath,
};
