import React from "react";
import PropTypes from "prop-types";
import styles from "./Icon.module.scss";
import PagePathUtility from "../../libs/PagePathUtility";

const Icon = ({ name, size, rotate, otherClass = "", onClick }) => {
  // global component to show svg icon

  if (!name) return null;

  let additionalInlineStyles = {};
  if (rotate) {
    additionalInlineStyles = { transform: `rotate(${rotate}deg)` };
  }

  return (
    <svg
      className={`icon ${otherClass} ${styles[`icon--${size}`]}`}
      style={additionalInlineStyles}
      onClick={onClick}
    >
      <use
        xlinkHref={PagePathUtility.createLinkWithBasepath(
          `/static/icons/sprite.svg#icon-${name}`
        )}
      />
    </svg>
  );
};

Icon.defaultProps = {
  name: null,
  size: "default",
  rotate: null,
  otherClass: "",
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  rotate: PropTypes.number,
  otherClass: PropTypes.string,
};

export default Icon;
