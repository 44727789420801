import React from 'react';
import Router, { useRouter } from 'next/router';
import Link from 'next/link'
import Icon from './Icon';

const mainNavigation = props => {

    // Global Header Component
    const router = useRouter();

    const handleRootItemClick = (target) => {
        const listItem = target.closest("li");
        if(listItem?.classList.contains("open")) {
            listItem?.classList.remove('open')
        }else {
            listItem?.classList.add('open')
        }
    }

    /**
     * Render menu recursive
     * @param items
     * @returns {XML}
     */
    const renderMenu = (items, itemClass, level = 0) => {
        return (
            <>
                <ul className={`${itemClass} ${itemClass}-${level} no-list`}>
                    {Object.values(items).map((menuItem, i) => {
                        // console.log(menuItem);
                        if(!menuItem.nextLinkAs) return null;
                        return (
                            <li key={i}>
                                {/*Internal links*/}
                                {menuItem.nextLinkHref && !menuItem.nextLinkAs.startsWith('http') && (
                                    <Link href={menuItem.nextLinkAs.replace(/\/$/, "")} prefetch={false}>
                                        <a
                                            className={` hover-effect-border ${router.asPath === menuItem.nextLinkAs.replace(/\/$/, "")? 'active' : ''}`}
                                        >
                                            {menuItem.title}
                                        </a>
                                    </Link>
                                )}
                                {level === 0 && Array.isArray(menuItem.children) && menuItem.children.length > 0 && (
                                    <div
                                        className="open-toggle"
                                        onClick={
                                            (e) => handleRootItemClick(e.target)
                                        }>
                                            <Icon name="expand" size="medium" otherClass="show-for-small-only" />
                                    </div>
                                )}
                                {/*External links*/}
                                {menuItem.nextLinkHref && menuItem.nextLinkAs.startsWith('http') && (
                                    <a className="hover-effect-border" target={menuItem.target} href={menuItem.nextLinkAs}>{menuItem.title}</a>
                                )}

                                {menuItem.children && renderMenu(menuItem.children, 'mainmenu__sublist', level + 1)}
                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }

    return props.navigationItems ? renderMenu(props.navigationItems, 'mainmenu__list') : null;
}

export default mainNavigation;