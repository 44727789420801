// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://aba56b325e3149a19d4af0463f6386d7@o33592.ingest.sentry.io/1389815",
  tracesSampleRate: 0.01,
  // enabled: process.env.NODE_ENV === "production",
  ignoreErrors: ["Non-Error promise rejection captured"],
  allowUrls: [/lto\.de/],
  normalizeDepth: 10,
});
