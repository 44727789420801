//Action Types
const JOBMARKET_INITIALIZED = "JOBMARKET_INITIALIZED";
const JOBS_DID_MOUNT = "JOBS_DID_MOUNT";
const SET_SEARCH_HISTORY = "SET_SEARCH_HISTORY";

const ADD_NEXT_SEARCH = "ADD_NEXT_SEARCH";

const FETCH_JOB_SEARCH = "FETCH_JOB_SEARCH";
const FETCH_JOB_SEARCH_PENDING = "FETCH_JOB_SEARCH_PENDING";
const FETCH_JOB_SEARCH_SUCCESS = "FETCH_JOB_SEARCH_SUCCESS";
const FETCH_JOB_SEARCH_ERROR = "FETCH_JOB_SEARCH_ERROR";
const FETCH_JOB_SEO_SEARCH_SUCCESS = "FETCH_JOB_SEO_SEARCH_SUCCESS";
const MOVE_SEARCH_FROM_HISTORY_LAST_SEARCH =
  "MOVE_SEARCH_FROM_HISTORY_LAST_SEARCH";
const MOVE_JOB_TO_HISTORY = "MOVE_JOB_TO_HISTORY";
const TOGGLE_SEARCH_FILTER = "TOGGLE_SEARCH_FILTER";
const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
const SET_SORTING = "SET_SORTING";
const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
const SET_SEARCH_LOCATION = "SET_SEARCH_LOCATION";
const SET_SEARCH_LOCATION_RADIUS = "SET_SEARCH_LOCATION_RADIUS";

const SET_USER = "SET_USER";

const MOVE_LAST_SEARCH_TO_HISTORY = "MOVE_LAST_SEARCH_TO_HISTORY";
const MOVE_SEARCH_HISTORY_ITEM_TO_TOP = "MOVE_SEARCH_HISTORY_ITEM_TO_TOP";
const SAVE_BOOSTING = "SAVE_BOOSTING";
const RESET_SEARCH = "RESET_SEARCH";
const DELETE_LOCATION = "DELETE_LOCATION";
const DELETE_QUERY = "DELETE_QUERY";
const DELETE_FACET = "DELETE_FACET";
const LOAD_SEARCH_HISTORY = "LOAD_SEARCH_HISTORY";
const NEWSLETTER_SUBSCRIBE_CLEAR = "NEWSLETTER_SUBSCRIBE_CLEAR";
const NEWSLETTER_SUBSCRIBE_SUCCESS = "NEWSLETTER_SUBSCRIBE_SUCCESS";
const NEWSLETTER_SUBSCRIBE_FAILED = "NEWSLETTER_SUBSCRIBE_FAILED";
const SET_LAST_VISIT = "SET_LAST_VISIT";
const SET_JOBS_HISTORY = "SET_JOBS_HISTORY";
const SEARCH_HISTORY_INITIALIZED = "SEARCH_HISTORY_INITIALIZED";

export default {
  JOBMARKET_INITIALIZED,
  JOBS_DID_MOUNT,
  SET_SEARCH_HISTORY,
  ADD_NEXT_SEARCH,
  FETCH_JOB_SEARCH,
  FETCH_JOB_SEARCH_PENDING,
  FETCH_JOB_SEARCH_SUCCESS,
  FETCH_JOB_SEARCH_ERROR,
  FETCH_JOB_SEO_SEARCH_SUCCESS,
  MOVE_SEARCH_FROM_HISTORY_LAST_SEARCH,
  MOVE_LAST_SEARCH_TO_HISTORY,
  MOVE_SEARCH_HISTORY_ITEM_TO_TOP,
  MOVE_JOB_TO_HISTORY,
  SAVE_BOOSTING,
  RESET_SEARCH,
  DELETE_LOCATION,
  DELETE_QUERY,
  DELETE_FACET,
  LOAD_SEARCH_HISTORY,
  NEWSLETTER_SUBSCRIBE_CLEAR,
  NEWSLETTER_SUBSCRIBE_SUCCESS,
  NEWSLETTER_SUBSCRIBE_FAILED,
  SET_LAST_VISIT,
  SET_JOBS_HISTORY,
  SEARCH_HISTORY_INITIALIZED,
  TOGGLE_SEARCH_FILTER,
  SET_SEARCH_FILTER,
  SET_SORTING,
  SET_SEARCH_QUERY,
  SET_SEARCH_LOCATION,
  SET_SEARCH_LOCATION_RADIUS,
  SET_USER,
};
