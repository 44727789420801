import { createContext, useState } from "react";

export const EmployerManagementContext = createContext(null);

const EmployerManagementContextProvider = ({ children }) => {
  const [jobIdToCopy, setJobIdToCopy] = useState(null);

  const prepareJobDataToCopy = (job) => {
    if (!job) return job;

    const jobDataToCopy = {
      starttime: 0,
      hidden: true,
      format: job.format,
      title: `Kopie ${job.title}`,
      applyUrl: job.applyUrl,
      locationsEmployer: job.locationsEmployer,
      contacts: job.contacts,
      teaser: job.teaser,
      description: job.description,
      descriptionResponsibilitiesHeader: job.descriptionResponsibilitiesHeader,
      descriptionResponsibilities: job.descriptionResponsibilities,
      descriptionQualificationsHeader: job.descriptionQualificationsHeader,
      descriptionQualifications: job.descriptionQualifications,
      descriptionBenefitsHeader: job.descriptionBenefitsHeader,
      descriptionBenefits: job.descriptionBenefits,
      descriptionFinalNote: job.descriptionFinalNote,
      fieldOfLaws: job.fieldOfLaws,
      activities: job.activities,
      careerLevels: job.careerLevels,
      employmentTypes: job.employmentTypes,
      // benefits: job.benefits.map((benefit) => ({ id: benefit.id })),
      organisationalForm: { id: job.organisationalForm.id },
      videoType: job.videoType,
      videoId: job.videoId,
      // slug
      // statisticToken
    };

    return jobDataToCopy;
  };

  return (
    <EmployerManagementContext.Provider
      value={{
        jobIdToCopy,
        setJobIdToCopy,
        prepareJobDataToCopy,
      }}
    >
      {children}
    </EmployerManagementContext.Provider>
  );
};

export default EmployerManagementContextProvider;
