import React, { useEffect, useState, useRef } from "react";
import Icon from "../Icon";
import { useRouter } from "next/router";
import GeneralUtility from "../../libs/GeneralUtility";
import useTranslation from "next-translate/useTranslation";
import Button from "../Button";

const HeaderSearchForm = ({ isDisabledSearch, setIsDisabledSearch }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const textInput = useRef(null);
  const [inputSearch, inputSearchUpdate] = useState("");

  const handleSearch = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    const searchPath = `/suche#gsc.tab=0&gsc.q=${inputSearch}&gsc.sort=`;
    if (router.pathname === "/suche") {
      window.location = searchPath;
      // window.location.reload(true)
    } else {
      router.push(searchPath);
    }
    setIsDisabledSearch(false);
  };

  // open and close form search in header
  const toggleSearch = (e) => {
    setIsDisabledSearch((isDisabledSearch) => !isDisabledSearch);
  };

  useEffect(() => {
    if (isDisabledSearch) {
      textInput.current.focus();
    }
  }, [isDisabledSearch]);

  return (
    <>
      <form
        className={`page-header__form ${isDisabledSearch && "open"}`}
        onSubmit={handleSearch}
      >
        <input
          name="search"
          type="text"
          value={inputSearch}
          placeholder={t("search.inputPlaceholder")}
          onChange={(e) => inputSearchUpdate(e.target.value)}
          required
          ref={textInput}
        />
        <Button layout="custom" type={"submit"}>
          <Icon name="lupe" size="small" />
          <span>{t("search.searchButton")}</span>
        </Button>
      </form>
      <button onClick={toggleSearch}>
        <Icon name={isDisabledSearch ? "close" : "lupe"} size="default" />
      </button>
    </>
  );
};

export default HeaderSearchForm;
