// Fetch.js
import fetch from "isomorphic-unfetch";
import { ApiError } from "./Error";
import GeneralUtility from "./GeneralUtility";
import { getSession, signout } from "next-auth/client";
import * as Sentry from "@sentry/nextjs";

/**
 *
 * @param url
 * @param params
 * @param method
 * @param convertResponseToJson
 * @param additionalOptions
 * @returns {Promise<*>}
 */
export const fetchData = async (
  url,
  params,
  method = "GET",
  convertResponseToJson = true,
  additionalOptions = {}
) => {
  let options = {
    method,
    headers: {
      "content-type": "application/json; charset=utf-8",
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
    },
  };

  if (Object.values(additionalOptions).length > 0) {
    options = GeneralUtility.mergeDeep(options, additionalOptions);
  }

  if (params) {
    if (method === "GET" && Object.keys(params).length > 0) {
      url += url.includes("?") ? "&" : "?";

      url += objectToQueryString(params);
    } else if (method !== "GET") {
      if (!additionalOptions.noAPIKey) {
        params = {
          ...params,
          apiKey: process.env.NEXT_PUBLIC_API_KEY,
        };
      }
      options.body = JSON.stringify(params);
    }
  }

  if (!url.startsWith("http")) {
    url = process.env.NEXT_PUBLIC_CAREER_API_DOMAIN + url;
  }

  if (process.env.NEXT_PUBLIC_DEBUG === "true") {
    console.log(!url.startsWith("http"));
    console.log("Send request");
    console.log(url);
    console.log(options);
  }

  const handleErrors = async (resp) => {
    if (!resp.ok) {
      const session = await getSession();
      const noErrorResponseCodes = [404, 204];
      if (!noErrorResponseCodes.includes(resp.status)) {
        if (resp.status === 480) {
          // TODO Think about moving this to candidate related global fetch function
          if (
            (session?.user?.type === "candidate" ||
              session?.user?.type === "employer") &&
            !GeneralUtility.isServerSide()
          ) {
            // to prevent issues on dev server with active basic auth we send 480 instead of 401
            // see libs/Api/NextApiResponseUtility.js

            // logout in case of active client session and invalid session error from api
            await signout();
          }
          return resp;
        }

        let rawResponse = null;
        try {
          rawResponse = convertResponseToJson ? await resp.json() : await resp;
        } catch (e) {
          rawResponse = await resp;
        }
        const exception = new ApiError(
          "An error occurred while fetching the data.",
          rawResponse,
          resp.status
        );
        if (
          process.env.NODE_ENV === "production" &&
          ![401].includes(resp.status) // do not capture unauthorized errors
        ) {
          Sentry.captureException(exception, {
            extra: {
              user: session?.user?.email || "no_user",
              requestUrl: url,
              status: resp.status,
              requestParams: params,
              rawResponse,
            },
          });
        }
        throw exception;
      }
    }

    return resp;
  };

  return await fetch(url, options)
    .then(handleErrors)
    .then(async (resp) => {
      if (convertResponseToJson) {
        try {
          return await resp.json();
        } catch (e) {
          return resp;
        }
      } else {
        return resp;
      }
    })
    .then((resp) => {
      if (process.env.NEXT_PUBLIC_DEBUG === "true") {
        console.log("############");
        console.log("result");
        console.log(resp);
        console.log("############");
      }
      return resp;
    });
};

const objectToQueryString = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
