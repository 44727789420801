import UserApiServiceFrontend from "./Frontend";
import UserApiServiceBackend from "./Backend";

const backendApiService = UserApiServiceBackend;
const frontendApiService = UserApiServiceFrontend;

export default new Proxy(backendApiService, {
  get(target, name) {
    return function () {
      if (typeof window !== "undefined" && frontendApiService[name]) {
        return frontendApiService[name](...arguments);
      }
      return Reflect.get(target, name)(...arguments);
    };
  },
});
