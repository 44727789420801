import Icon from "../Icon";
import React from "react";
import styles from "./ScrollToTop.module.scss";
import PropTypes from "prop-types";

const ScrollToTop = ({ hide }) => {
  const handleScollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`action-item action-item--scroll-top ${
        hide ? "action-item--hide" : ""
      }`}
      onClick={handleScollToTop}
    >
      <span className={`action-item__icon ${styles.wrapper}`}>
        <Icon name="expand" size="large" otherClass={styles.icon} />
      </span>
    </div>
  );
};

ScrollToTop.propTypes = {
  hide: PropTypes.bool,
};

export default ScrollToTop;
