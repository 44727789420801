import React, { forwardRef } from "react";
import Icon from "../Icon";
import { SIZE, Spinner } from "baseui/spinner";

// eslint-disable-next-line react/display-name
const Button = React.forwardRef(
  (
    {
      id,
      onClick,
      onLinkClickCustom,
      href,
      text,
      icon, // TODO remove this option
      classes, // TODO remove this option
      customClassName = "",
      target = "_self",
      disabled = false,
      layout = "primary",
      type = "link",
      startEnhancer = null,
      isLoading = false,
      alt,
      children,
    },
    ref
  ) => {
    /**
     * trigger next.js internal onClick and custom event
     * @param e
     */
    const onLinkClick = (e) => {
      onClick && !disabled && onClick(e);
      onLinkClickCustom && !disabled && onLinkClickCustom(e);
    };

    const layouts = {
      primary: "button button--primary button--center",
      secondary: "button button--secondary button--center",
      hollowForDark: "button button--hollow button--center",
      hollowForLight:
        "button button--hollow button--hollow-light button--center",
      custom: "",
    };

    // eslint-disable-next-line react/display-name
    const Component = React.forwardRef((props, ref) =>
      type === "link" ? <a {...props} /> : <button {...props} />
    );

    return (
      <Component
        id={id}
        className={`${layouts[layout]} ${disabled ? "disabled" : ""} ${
          customClassName ? customClassName : ""
        }`}
        href={href}
        onClick={onLinkClick}
        ref={ref}
        target={target}
        alt={alt}
      >
        {isLoading && (
          <Spinner
            $color="#440DEB"
            $size={"10px"}
            overrides={{
              TrackPath: {
                style: {
                  fill: layout === "primary" ? "#fff" : "inherit",
                  opacity: layout === "primary" ? 0.9 : 0.15,
                },
              },
            }}
          />
        )}
        {/* TODO remove static icon if not used anymore*/}
        {icon && <Icon name="email" size="default" />}
        {startEnhancer && startEnhancer()}
        {text ? text : children}
      </Component>
    );
  }
);

export default Button;
