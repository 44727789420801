import React from "react";
import { useState } from "react";
import Icon from "../Icon";
import dynamic from "next/dynamic";
const NewsletterForm = dynamic(
  () => import("../../components/NewsletterForm/NewsletterForm"),
  { ssr: false }
);

const Subscription = () => {
  const [statePopUp, updatePopUp] = useState(false);

  const handlerPopUpNewsletter = (e) => {
    updatePopUp(!statePopUp);
  };

  return (
    <>
      <div className={`popUp_subscription ${statePopUp && "open"}`}>
        <div className="popUp_subscription__scroll">
          {statePopUp && (
            <NewsletterForm
              searchArgs={[]}
              activeSearch={true}
              errorResponse={true}
              responseMessages={[]}
            />
          )}
        </div>
      </div>

      <div
        className="action-item action-item--subscription"
        onClick={handlerPopUpNewsletter}
      >
        {statePopUp ? (
          <span className="action-item__icon action-item__icon--close">
            <Icon name="close" size="default" />
          </span>
        ) : (
          <span className="action-item__icon">
            <Icon name="newsletter" size="medium" />
          </span>
        )}
      </div>
      <div className="action-item__hover">
        <p className="action-item__text">Newsletter</p>
      </div>
    </>
  );
};

export default Subscription;
