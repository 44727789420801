import React from 'react';
import { getLayout as getMainLayout } from './MainLayout';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const DefaultPageLayout = ({ page, pageContents, children }) => (

  <>
    {page && !page.hideBreadcrumb && <Breadcrumb items={page.rootline} />}
    <div className="page-content__content-elements max-content">
      {children}
    </div>
  </>
);

export const getLayout = (page, pageProps) => getMainLayout(<DefaultPageLayout {...pageProps}>{page}</DefaultPageLayout>, pageProps);

export default DefaultPageLayout;
