const initialState = {
  initialized: false,
  activeSearch: false,
  pending: false,
  error: null,
  page: 1,
  jobs: [],
  search: {
    args: {
      query: "",
      location: "",
      radius: 50,
      resultsPerPage: 20,
      sort: "rel_desc",
      filter: [],
    },
    meta: {},
  },
  searchArgs: [],
  facets: [],
  jobsFound: 0,
  isSsr: true,
  isSeoPage: false,
  newsletter: {
    newsletterFormError: true,
    newsletterFormMessages: [],
  },
  nextSearch: {},
  lastSearch: null,
  searchHistoryInitialized: false,
  searchHistory: [],
  jobsHistory: [],
  boosting: [],
  lastVisit: 0,
  i: 1,
  user: null,
};

export default initialState;
