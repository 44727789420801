/**
 * Extract url paths of nested navigation
 *
 * @param pageRecords
 * @returns {Array}
 */
const getAllPathsOfMenu = (pageRecords) => {
  let paths = [];

  for (let i = 0; i < pageRecords.length; i++) {
    const pageRecord = pageRecords[i];

    let path = `${pageRecord.link}`;

    if (!path.startsWith("http")) {
      path = path.substring(1);
      path = path.replace(/\/$/, "");

      paths.push({
        params: {
          slug: path === "" ? [] : path.split("/"),
        },
      });
    }

    if (pageRecord.children) {
      const childPages = getAllPathsOfMenu(pageRecord.children);
      paths = [...paths, ...childPages];
    }
  }

  return paths;
};

const getPathFromSlug = (params) => {
  // get path to check against cms
  let path = "/";
  if (params && params.slug) {
    if (params.slug.length === 1 && params.slug[0] === "[[...slug]]") {
      // Fix for root request
      return "/";
    } else {
      path += params.slug.join("/");
    }
  }
  return path;
};

/**
 * API returns in case of only one element on page a object instead of an array with only one element.
 * Therefore we convert the content elements to an array.
 *
 * @param contentElements
 * @returns {*}
 */
const unifyContentArray = (contentElements) => {
  if (Array.isArray(contentElements)) {
    contentElements = [...contentElements];
  } else if (contentElements) {
    contentElements = [contentElements];
  }
  return contentElements;
};

const getImageManipulationSegment = (manipulationPath, argument, value) => {
  if (value) {
    manipulationPath += manipulationPath ? "," : "";
    manipulationPath += `${argument}|${value}`;
  }
  return manipulationPath;
};

const getItemSizeConfigFromColumnArray = (layoutConfig, index) => {
  const layoutPatternItems = layoutConfig.pattern;

  if (index >= layoutPatternItems.length && layoutConfig.config.repeatLast) {
    return layoutPatternItems[layoutPatternItems.length - 1];
  } else {
    return layoutPatternItems[index % layoutPatternItems.length];
  }
};

export default {
  getAllPathsOfMenu,
  getPathFromSlug,
  unifyContentArray,
  getImageManipulationSegment,
  getItemSizeConfigFromColumnArray,
};
