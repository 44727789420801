module.exports = {
  locales: ["de"],
  defaultLocale: "de",
  pages: {
    "*": ["common"],
  },
  localeDetection: false,
  loadLocaleFrom: (lang, ns) =>
    import(`./public/static/locales/${lang}/${ns}.json`).then((m) => m.default),
};
