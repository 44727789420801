import React from "react";
import NextImage from "next/image";
import styles from "./CareerLoading.module.scss";
import PropTypes from "prop-types";
import PagePathUtility from "../../libs/PagePathUtility";

const CareerLoading = ({ text }) => {
  return (
    <div className={styles.ring}>
      <div className={styles.logo}>
        <NextImage
          src={PagePathUtility.createLinkWithBasepath(
            "/static/img/lto-karriere_logo-with-underline.svg"
          )}
          width={80}
          height={80}
        />
      </div>
      <span className={styles.span} />
      {text && <span className={styles.subText}>{text}</span>}
    </div>
  );
};

CareerLoading.propTypes = {
  text: PropTypes.string,
};

export default CareerLoading;
