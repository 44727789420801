import { fetchData } from "../../../libs/Fetch";
import ContentLibs from "../libs/ContentLibs";
import { PageNotFoundError } from "../error/PageNotFoundError";

const apiBasePath = "/rest/v1";
const apiAdditionalPath = "/api";

const fetchPage = (path, preview = false) => {
  return fetchData(
    `${process.env.NEXT_PUBLIC_CAREER_API_DOMAIN_BACKEND_REQUESTS}${path}`,
    { type: 1588851209 },
    "GET",
    false
  ).then((resp) => {
    if (!resp.ok) {
      switch (resp.status) {
        case 404:
          throw new PageNotFoundError();
          break;
      }
    }
    return resp.json();
  });
};

const fetchPageByUid = (uid, preview = false) => {
  return fetchData(
    `${process.env.NEXT_PUBLIC_CAREER_API_DOMAIN_BACKEND_REQUESTS}/index.php?id=${uid}`,
    { type: 1588851209 }
  );
};

const fetchMenu = (menu, nextJsPages) => {
  const url = "/menu/";
  let request = { menuKey: menu };
  let items = fetchData(
    process.env.NEXT_PUBLIC_CAREER_API_DOMAIN_BACKEND_REQUESTS +
      apiBasePath +
      url +
      "?type=1588751089",
    request,
    "POST"
  );

  return items.then((response) => {
    let navigationItems = response?.result.menu;
    if (navigationItems) {
      addLinkParamsToMenuItems(navigationItems, nextJsPages);
    }
    return response;
  });
};

/**
 * Adds href property based on existence of link as next.js page
 * @param navigationItems
 * @param nextJsPages
 * @returns {*}
 */
const addLinkParamsToMenuItems = (
  navigationItems,
  nextJsPages,
  urlField = "link"
) => {
  navigationItems.map((menuItem) => {
    if (nextJsPages && nextJsPages.includes(menuItem[urlField])) {
      // link to existing next.js pages
      // TODO remove nextLinkHref
      menuItem.nextLinkHref = menuItem[urlField];
      menuItem.nextLinkAs = menuItem[urlField];
    } else {
      // link to dynamic cms pages
      // TODO remove nextLinkHref
      menuItem.nextLinkHref = "/";
      menuItem.nextLinkAs = menuItem[urlField];
    }

    if (menuItem.data) {
      menuItem.uid = menuItem.data.uid;

      // only add filtered data
      const allowedDataProps = ["uid"];
      menuItem.data = Object.keys(menuItem?.data)
        .filter((key) => allowedDataProps.includes(key))
        .reduce((cur, key) => {
          return { ...cur, [key]: menuItem.data?.[key] };
        }, {});
    }

    if (menuItem.children) {
      addLinkParamsToMenuItems(menuItem.children, nextJsPages);
    }
  });
  return navigationItems;
};

const getImageUrl = (
  imageUid,
  config = {},
  idIsReference = true,
  domain = ""
) => {
  if (!imageUid) return null;

  if (!domain) {
    domain = process.env.NEXT_PUBLIC_CAREER_API_DOMAIN;
  }

  let imagePath = domain + apiBasePath;

  if (idIsReference) {
    imagePath += `/image-reference/${imageUid}/`;
  } else {
    imagePath += `/image/${imageUid}/`;
  }

  let manipulationPath = "";

  ["width", "height", "maxWidth", "cropVariant"].map((argument) => {
    if (argument && config[argument]) {
      manipulationPath = ContentLibs.getImageManipulationSegment(
        manipulationPath,
        argument,
        config[argument]
      );
    }
  });

  if (manipulationPath) {
    imagePath += `${manipulationPath}/`;
  }

  return imagePath;
};

const getCTypeEditFieldsMapping = async (domain = "") => {
  if (!domain) {
    domain = process.env.NEXT_PUBLIC_CAREER_API_DOMAIN_BACKEND_REQUESTS;
  }
  const path = domain + apiAdditionalPath + "/cType/mapping";
  return fetchData(path);
};

export default {
  fetchPage,
  fetchPageByUid,
  fetchMenu,
  getImageUrl,
  addLinkParamsToMenuItems,
  getCTypeEditFieldsMapping,
};
