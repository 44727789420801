import React, {useContext} from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {PageContext} from "../../modules/cms_content/context/PageContext";

const Menu = ({ items, isDisabledSearch }) => {
  const router = useRouter();
  const { page } = useContext(PageContext);

  const renderNavigation = (navigationItems, level = 1) => {
    return (
      <ul className={`menu-level--${level}`}>
        {navigationItems &&
          Object.values(navigationItems).map((menuItem, i) =>
            renderNavigationItem(menuItem, level)
          )}
      </ul>
    );
  };

  const renderNavigationItem = (menuItem, level) => {
    if (!menuItem.nextLinkAs) return null;
    const pathOfMenuItem = menuItem.nextLinkAs.replace(/\/$/, "");

    return (
      <li
        className={page?.rootline?.find(item => item.uid === menuItem?.data?.uid) ? "active" : ""}
        key={menuItem.nextLinkAs}
      >
        <Link href={pathOfMenuItem} prefetch={false}>
          <a className="hover-effect-border hover-effect-border--white">
            {menuItem.title}
          </a>
        </Link>
        {Array.isArray(menuItem.children) &&
          menuItem.children.length > 0 &&
          renderNavigation(menuItem.children, ++level)}
      </li>
    );
  };

  return (
    <div className={`page-header__menutop ${isDisabledSearch && "search"}`}>
      {renderNavigation(items)}
    </div>
  );
};

export default Menu;
