import Fetch, { fetchData } from "../../../../libs/Fetch";
import ContentApiService from "../../../cms_content/services/ContentApiService";
import { useCookies } from "react-cookie";
import PagePathUtility from "../../../../libs/PagePathUtility";

const apiBasePath = "/rest/v1";

const staticUserProps = {
  type: "ltoCandidate",
};

const fetch = (
  url,
  payload = {},
  method = "GET",
  headers = {},
  convertResponseToJson = true
) => {
  if (!url.startsWith("http")) {
    url = apiBasePath + url;
  }

  return fetchData(url, payload, method, convertResponseToJson, { headers });
};

const UserApiBackendService = {
  /**
   * Fetch registration
   * @param userData
   */
  fetchUserRegistration: (userData) => {
    const url = "/candidate/create/";

    const request = {
      ...userData,
    };

    return fetch(url, request, "PUT");
  },

  /**
   * Fetch userdata
   */
  fetchUserdata: (sessionId, userId) => {
    const url = "/candidate/show/";

    const request = {
      user: {
        ...staticUserProps,
        uid: userId,
      },
    };

    return fetch(url, request, "POST", { Cookie: `fe_typo_user=${sessionId}` });
  },

  /**
   * Fetch update
   */
  fetchUserdataUpdate: (userData, session = {}) => {
    const url = "/candidate/update/";
    const {
      sessionId,
      user: { uid: userId },
    } = session;

    const request = {
      ...userData,
      user: {
        ...(userData?.user || {}),
        uid: userId,
      },
    };

    return fetch(url, request, "POST", {
      Cookie: `fe_typo_user=${sessionId}`,
    });
  },

  /**
   * Fetch update
   */
  fetchUserDelete: (session = {}) => {
    const url = "/candidate/delete/";
    const {
      sessionId,
      user: { uid: userId },
    } = session;

    const request = {
      user: {
        uid: userId,
      },
    };

    return fetch(url, request, "DELETE", {
      Cookie: `fe_typo_user=${sessionId}`,
    });
  },

  /**
   * Fetch registration
   * @param userId
   * @param token
   */
  fetchUserConfirm: (userId, token) => {
    const url = "/candidate/confirm/";

    const props = {
      uid: userId,
      token,
      type: "general",
    };

    const request = {
      user: {
        ...props,
      },
    };

    return fetch(url, request, "POST");
  },

  /**
   * Fetch update
   */
  fetchUserFile: (fileId, sessionId) => {
    const url = ContentApiService.getImageUrl(fileId);
    return fetch(
      url,
      {},
      "GET",
      {
        Cookie: `fe_typo_user=${sessionId}`,
      },
      false
    );
  },

  getUserFileUrl: (fileId) =>
    PagePathUtility.createLinkWithBasepath(`/api/v1/user/file/${fileId}`),

  fetchJobApplyFile: (jobUid, sessionId) => {
    const url = "/candidate/apply-file/";

    return fetch(
      url,
      jobUid ? { job: { uid: jobUid } } : {},
      "POST",
      {
        Cookie: `fe_typo_user=${sessionId}`,
      },
      false
    );
  },

  fetchJobApply: (jobUid, locationIds, activityIds, sessionId) => {
    const url = "/candidate/apply/";

    return fetch(
      url,
      jobUid
        ? {
            job: {
              uid: jobUid,
              locations: locationIds,
              activities: activityIds,
            },
          }
        : {},
      "POST",
      {
        Cookie: `fe_typo_user=${sessionId}`,
      }
    );
  },

  /**
   * Fetch user update password
   * @param currentPassword
   * @param newPassword
   * @param session
   */
  fetchUserUpdatePassword: (currentPassword, newPassword, session = {}) => {
    const url = "/user/updatePassword/";

    const {
      sessionId,
      user: { uid: userId },
    } = session;

    const props = {
      uid: userId,
      currentPassword,
      newPassword,
      ...staticUserProps,
    };

    const request = {
      user: {
        ...props,
      },
    };

    return fetch(url, request, "POST", { Cookie: `fe_typo_user=${sessionId}` });
  },

  /**
   * Fetch userdata
   */
  fetchConfirmEmailAgain: (email) => {
    const url = "/user/sendConfirmationEmail/";

    const request = {
      user: {
        email,
        ...staticUserProps,
      },
    };

    return fetch(url, request, "POST");
  },

  /**
   * Fetch userdata
   */
  fetchSendForgotPasswordEmail: (email) => {
    const url = "/user/sendForgotPasswordEmail/";

    const request = {
      user: {
        email,
        ...staticUserProps,
      },
    };

    return fetch(url, request, "POST");
  },

  /**
   * Fetch confirm update password
   */
  fetchConfirmUpdatePassword: (uid, token, password) => {
    const url = "/user/confirmUpdatePassword/";

    const request = {
      user: {
        uid,
        token,
        password,
        ...staticUserProps,
      },
    };

    return fetch(url, request, "POST");
  },
};

export default UserApiBackendService;
