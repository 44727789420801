import React, { useState, useEffect } from "react";
import Router from "next/router";
import Link from "next/link";
import { useSession } from "next-auth/client";
import useTranslation from "next-translate/useTranslation";
import useScrollingWindow from "../../hooks/useScrollWindow";
import HeaderSearchForm from "../HeaderSearchForm";
import Icon from "../Icon";
import Menu from "./Menu";
import PagePathUtility from "../../libs/PagePathUtility";
import CandidateUserMenu from "./UserMenu/CandidateUserMenu";
import EmployerUserMenu from "./UserMenu/EmployerUserMenu";

export const HEADER_EVENTS = {
  TOGGLE_MAIN_MENU: "HEADER_EVENTS_TOGGLE_MAIN_MENU",
};

const Header = ({ headerNavigationItems }) => {
  // Global Header Component
  const { t } = useTranslation("common");

  // custom hook to listen scrolling event
  const [stateScrolling] = useScrollingWindow(50);
  const [stateMainMenu, updateMainMenu] = useState(false);
  const [showMenu, updateShowMenu] = useState(false);
  const [isDisabledSearch, setIsDisabledSearch] = useState(false);
  const [session, loading] = useSession();
  const [showLoginContent, setShowLoginContent] = useState(false);
  const [showToLtoHint, setShowToLtoHint] = useState(false);
  const [ltoReferrerUrl, setLtoReferrerUrl] = useState("");

  // open anc close main menu
  const clickMainMenu = (e) => {
    e && e.preventDefault();

    updateMainMenu(!stateMainMenu);
    setShowLoginContent(false);

    window.dataLayer.push({
      event: "mainMenuClick",
      state: stateMainMenu ? "close" : "open",
      portal: "lto-karriere",
    });

    if (stateMainMenu) {
      closeMainMenu();
    } else {
      document.body.classList.add("no-scroll");
      document.body.classList.add("opened-menu");
      setIsDisabledSearch(false);
      updateMainMenu(true);
    }
  };
  // close main menu once new start
  Router.events.on("routeChangeStart", (url) => {
    if (stateMainMenu) {
      closeMainMenu();
    }
    // hide back to lto button in case of route change
    showToLtoHint && setShowToLtoHint(false);
  });

  const closeMainMenu = () => {
    document.body.classList.remove("no-scroll");
    document.body.classList.remove("opened-menu");
    updateMainMenu(false);
  };

  // active main menu when a window loaded completely
  const activeMainMenu = (e) => updateShowMenu((showMenu) => !showMenu);

  useEffect(() => {
    if (!showMenu) {
      // delay to active main menu
      setTimeout(() => {
        activeMainMenu();
      }, 2000);
    }

    const toggleMenuListener = () => {
      clickMainMenu();
    };
    document.addEventListener(
      HEADER_EVENTS.TOGGLE_MAIN_MENU,
      toggleMenuListener
    );

    if (
      document.referrer.startsWith(process.env.NEXT_PUBLIC_ARTICLE_FE_DOMAIN) &&
      !document.referrer.startsWith(
        process.env.NEXT_PUBLIC_FRONTEND_DOMAIN + process.env.BASE_PATH
      )
    ) {
      setShowToLtoHint(true);
      setLtoReferrerUrl(document.referrer);
    }

    return () => {
      document.removeEventListener(
        HEADER_EVENTS.TOGGLE_MAIN_MENU,
        toggleMenuListener
      );
    };
  }, []);

  const handleLoginClick = (userIsLoggedIn) => {
    closeMainMenu();
    setShowLoginContent(userIsLoggedIn);
  };

  return (
    <>
      {showToLtoHint && (
        <a className="page-header-back-to-lto" href={ltoReferrerUrl}>
          {/*<span className="page-header-back-to-lto__close">*/}
          {/*  <Icon*/}
          {/*    name="close"*/}
          {/*    size="very-small"*/}
          {/*    onClick={() => setShowToLtoHint(false)}*/}
          {/*  />*/}
          {/*</span>*/}
          <span className="page-header-back-to-lto__link">
            <Icon name="chevron-right" otherClass="rotate-180" /> Zurück zu
            LTO.de
          </span>
        </a>
      )}
      <header
        className={`page-header ${stateScrolling ? "scrolling" : "initial"} ${
          stateMainMenu ? "menu-open" : "menu-close"
        }`}
      >
        <div>
          <Link href="/" prefetch={false}>
            <a className="page-header__logo page-header__logo-lto-karriere">
              <img
                className="page-header__logo--white"
                src={PagePathUtility.createLinkWithBasepath(
                  "/static/img/logo_lto_karriere_white.svg"
                )}
                width={150}
                height={30}
                alt="logo lto karriere"
              />
              <img
                className="page-header__logo--black"
                src={PagePathUtility.createLinkWithBasepath(
                  "/static/img/logo_lto_karriere_black.svg"
                )}
                width={150}
                height={30}
                alt="logo lto karriere"
              />
            </a>
          </Link>
          {/*<div className="page-header__logo-wrapper">*/}
          {/*  <a*/}
          {/*    href="https://www.lto.de"*/}
          {/*    className="page-header__logo"*/}
          {/*    onMouseEnter={() => setCheckbox(false)}*/}
          {/*    onMouseLeave={() => setCheckbox(true)}*/}
          {/*    title="Zu LTO wechseln"*/}
          {/*  >*/}
          {/*    <img*/}
          {/*      className="page-header__logo--white"*/}
          {/*      src="/static/img/logo_lto.svg"*/}
          {/*      width={150}*/}
          {/*      height={30}*/}
          {/*      alt="logo lto karriere"*/}
          {/*    />*/}
          {/*  </a>*/}
          {/*  /!* Logo LTO Stellemarkt *!/*/}
          {/*  <Checkbox*/}
          {/*    checked={checkbox}*/}
          {/*    onChange={(e) => {*/}
          {/*      setCheckbox(e.currentTarget.checked);*/}
          {/*    }}*/}
          {/*    checkmarkType={STYLE_TYPE.toggle_round}*/}
          {/*    overrides={{*/}
          {/*      Root: {*/}
          {/*        style: {*/}
          {/*          margin: "0 .5rem",*/}
          {/*        },*/}
          {/*      },*/}
          {/*      Label: {*/}
          {/*        style: {*/}
          {/*          display: "none",*/}
          {/*        },*/}
          {/*      },*/}
          {/*      Toggle: {*/}
          {/*        style: ({ $checked }) => ({*/}
          {/*          backgroundColor: $checked ? "#df1051" : "#fff",*/}
          {/*        }),*/}
          {/*      },*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Link href="/" prefetch={false}>*/}
          {/*    <a className="page-header__logo page-header__logo-lto-karriere">*/}
          {/*      <img*/}
          {/*        className="page-header__logo--white"*/}
          {/*        src="/static/img/logo_lto_karriere_white.svg"*/}
          {/*        width={150}*/}
          {/*        height={30}*/}
          {/*        alt="logo lto karriere"*/}
          {/*      />*/}
          {/*      <img*/}
          {/*        className="page-header__logo--black"*/}
          {/*        src="/static/img/logo_lto_karriere_black.svg"*/}
          {/*        width={150}*/}
          {/*        height={30}*/}
          {/*        alt="logo lto karriere"*/}
          {/*      />*/}
          {/*    </a>*/}
          {/*  </Link>*/}
          {/*</div>*/}

          {/* Top Menu */}
          <Menu
            items={headerNavigationItems}
            isDisabledSearch={isDisabledSearch}
          />

          <div className="page-header__menumeta">
            <div
              className={`item page-header__search ${
                isDisabledSearch && "open"
              }`}
            >
              <HeaderSearchForm
                isDisabledSearch={isDisabledSearch}
                setIsDisabledSearch={setIsDisabledSearch}
              />
            </div>
            {!session ? (
              <Link href="/login" prefetch={false}>
                <a className="item page-header__login">
                  <Icon name="login" size="medium" />
                  <span className="page-header__login-text">Login</span>
                </a>
              </Link>
            ) : (
              <div
                className={`item page-header__login ${
                  showLoginContent && "open-login"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  closeMainMenu();
                  handleLoginClick(!showLoginContent);
                }}
                onMouseEnter={(e) => {
                  e.preventDefault();
                  handleLoginClick(true);
                }}
                onMouseLeave={(e) => {
                  e.preventDefault();
                  closeMainMenu();
                  handleLoginClick(false);
                }}
              >
                <Icon name="login" size="medium" />
                <div className="page-header__login-content">
                  {session.user.type === "candidate" && (
                    <CandidateUserMenu session={session} />
                  )}
                  {session.user.type === "employer" && (
                    <EmployerUserMenu session={session} />
                  )}
                </div>
              </div>
            )}
            {/* Button to open main menu */}
            <a
              onClick={clickMainMenu}
              className={stateMainMenu ? "item show" : "item initial"}
            >
              <span className="page-header__burger">
                <span className="page-header__burger-top" />
                <span className="page-header__burger-middle" />
                <span className="page-header__burger-bottom" />
              </span>
              <span className="page-header__burger-text">
                {t("menu.title")}
              </span>
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
