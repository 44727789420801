import CookieStorage, { hasCookies } from "./CookieStorage";

/**
 * Select storage - fallback to sessionstorage and cookie
 * @returns {null}
 */
export const getStorage = () => {
  let storage = null;
  if (isStorageSupported("localStorage")) {
    storage = window.localStorage;
  } else if (isStorageSupported("sessionStorage")) {
    storage = window.sessionStorage;
  } else if (isStorageSupported("cookieStorage")) {
    storage = new CookieStorage();
  }
  return storage;
};

const isStorageSupported = (name = "localStorage") => {
  const storage = String(name)
    .replace(/storage$/i, "")
    .toLowerCase();

  if (storage === "local") {
    return hasStorage("localStorage");
  }

  if (storage === "session") {
    return hasStorage("sessionStorage");
  }

  if (storage === "cookie") {
    return hasCookies();
  }
};

const hasStorage = (name) => {
  try {
    const storage = window[name];
    storage.setItem("TEST_KEY", "1");
    storage.removeItem("TEST_KEY");
    return true;
  } catch (e) {
    return false;
  }
};
