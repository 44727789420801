export default {
  accordion: {
    collapse: "Collapse",
    expand: "Expand",
  },
  breadcrumbs: {
    ariaLabel: "Breadcrumbs navigation",
  },
  datepicker: {
    ariaLabel: "Select a date.",
    ariaLabelRange: "Select a date range.",
    ariaLabelCalendar: "Calendar.",
    ariaRoleDescriptionCalendarMonth: "Calendar month",
    previousMonth: "Previous month.",
    nextMonth: "Next month.",
    pastWeek: "Past Week",
    pastMonth: "Past Month",
    pastThreeMonths: "Past 3 Months",
    pastSixMonths: "Past 6 Months",
    pastYear: "Past Year",
    pastTwoYears: "Past 2 Years",
    screenReaderMessageInput:
      "Press the down arrow key to interact with the calendar and select a date. Press the escape button to close the calendar.",
    selectedDate: "Selected date is ${date}.",
    selectedDateRange:
      "Selected date range is from ${startDate} to ${endDate}.",
    selectSecondDatePrompt: "Select the second date.",
    quickSelectLabel: "Choose a date range",
    quickSelectAriaLabel: "Choose a date range",
    quickSelectPlaceholder: "None",
    timeSelectEndLabel: "End time",
    timeSelectStartLabel: "Start time",
    timePickerAriaLabel12Hour: "Select a time, 12-hour format.",
    timePickerAriaLabel24Hour: "Select a time, 24-hour format.",
    timezonePickerAriaLabel: "Select a timezone.",
    selectedStartDateLabel: "Selected start date.",
    selectedEndDateLabel: "Selected end date.",
    dateNotAvailableLabel: "Not available.",
    dateAvailableLabel: "It's available.",
    selectedLabel: "Selected.",
    chooseLabel: "Choose",
  },
  datatable: {
    emptyState:
      "No rows match the filter criteria defined. Please remove one or more filters to view more data.",
    loadingState: "Loading Rows.",
    searchAriaLabel: "Search by text",
    filterAdd: "Add Filter",
    filterExclude: "Exclude",
    filterApply: "Apply",
    filterAppliedTo: "filter applied to",
    optionsLabel: "Select column to filter by",
    optionsSearch: "Search for a column to filter by...",
    optionsEmpty: "No columns available.",
    categoricalFilterSelectAll: "Select All",
    categoricalFilterSelectClear: "Clear",
    categoricalFilterEmpty: "No Categories Found",
    datetimeFilterRange: "Range",
    datetimeFilterRangeDatetime: "Date, Time",
    datetimeFilterRangeDate: "Date",
    datetimeFilterRangeTime: "Time",
    datetimeFilterCategorical: "Categorical",
    datetimeFilterCategoricalWeekday: "Weekday",
    datetimeFilterCategoricalMonth: "Month",
    datetimeFilterCategoricalQuarter: "Quarter",
    datetimeFilterCategoricalHalf: "Half",
    datetimeFilterCategoricalFirstHalf: "H1",
    datetimeFilterCategoricalSecondHalf: "H2",
    datetimeFilterCategoricalYear: "Year",
    numericalFilterRange: "Range",
    numericalFilterSingleValue: "Single Value",
    booleanFilterTrue: "true",
    booleanFilterFalse: "false",
    booleanColumnTrueShort: "T",
    booleanColumnFalseShort: "F",
  },
  buttongroup: {
    ariaLabel: "button group",
  },
  fileuploader: {
    dropFilesToUpload: "Dateien zum Hochladen hier ablegen...",
    or: "",
    browseFiles: "Dateien durchsuchen",
    retry: "Upload wiederholen",
    cancel: "Abbrechen",
  },
  menu: {
    noResultsMsg: "No results",
    parentMenuItemAriaLabel:
      "You are currently at an item that opens a nested listbox. Press right arrow to enter that element and left arrow to return.",
  },
  modal: {
    close: "Schließen",
  },
  drawer: {
    close: "Schließen",
  },
  pagination: {
    prev: "Vorgerige",
    next: "Nächste",
    preposition: "von",
  },
  select: {
    noResultsMsg: "Kein Ergebnis gefunden",
    placeholder: "Auswählen...",
    create: "Hinzufügen von",
  },
  toast: {
    close: "Schließen",
  },
};
