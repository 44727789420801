import { applyMiddleware, createStore } from 'redux';
import {createWrapper} from 'next-redux-wrapper';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const middlewares = [thunk];

const makeStore = (context) => {
    return createStore(rootReducer, applyMiddleware(...middlewares));
};

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, {debug: false});