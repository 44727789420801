import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainMenu from "../components/MainMenu/MainMenu";
import Subscription from "../components/Subscription";
import Router, { useRouter } from "next/router";
import ContentApiService from "../modules/cms_content/services/ContentApiService";
import GeneralUtility from "../libs/GeneralUtility";
import PageGetter from "../modules/cms_content/libs/PageGetter";
import PagePathUtility from "../libs/PagePathUtility";
import Head from "next/head";
import PreviewInfo from "../components/PreviewInfo/PreviewInfo";
import getConfig from "next/config";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import CareerLoading from "../components/CareerLoading/CareerLoading";
import { SnackbarProvider } from "baseui/snackbar";
import ScrollToTop from "../components/ScrollToTop";
import StickyInfoBox from "../modules/user/components/StickyInfoBox";
import PageContextPopupMessages from "../modules/cms_content/components/global/PageContextPopupMessages";

const MainLayout = ({
  children,
  page,
  navigationMainHeaderMenu,
  navigationMain,
  navigationFooter,
  currentPage,
  fixedBottomActions,
  preview,
  previewData,
}) => {
  const { publicRuntimeConfig } = getConfig();
  const defaultSeoKeywords = publicRuntimeConfig.seoDefaults.keywords;
  const defaultSeoDescription = publicRuntimeConfig.seoDefaults.description;
  const [embedHotjar, setEmbedHotjar] = useState(false);
  const hotjarUrlPattern = ["/profil"];
  const router = useRouter();
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  const [showRegistrationBanner, setShowRegistrationBanner] = useState(
    page?.showRegistrationBanner
  );

  const handleRouteChangeComplete = (url) => {
    if (!!process.env.NEXT_PUBLIC_HTRACKING_ENABLED) {
      const enableHotjar = hotjarUrlPattern.some((urlPattern) =>
        url.startsWith(urlPattern)
      );
      setEmbedHotjar(enableHotjar);
    }
  };

  useEffect(() => {
    handleRouteChangeComplete(router.asPath);
    setShowRegistrationBanner(page?.showRegistrationBanner ?? true);
    if (page?.showScrollToTopBtn) {
      window.addEventListener("scroll", (event) => {
        const yPos = window.pageYOffset || document.documentElement.scrollTop;
        setShowScrollToTopButton(yPos > 400);
      });
    }

    return () => {
      window.removeEventListener("scroll");
    };
  }, [page]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      // unbind handle route change for analytics
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [Router.events]);

  const pageLayoutClass = page
    ? `page-layout--${GeneralUtility.convertCamelCaseToHyphens(
        PageGetter.getBackendLayout(page)
      )}`
    : "";

  return (
    <div className="page">
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <link
          rel="icon"
          href={PagePathUtility.createLinkWithBasepath("/favicon-16.ico")}
          sizes="16x16"
        />
        <link
          rel="icon"
          href={PagePathUtility.createLinkWithBasepath("/favicon-32.ico")}
          sizes="32x32"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />

        {page?.title && (
          <>
            <title key="title">{`${page.seoTitle || page.title}${
              currentPage && parseInt(currentPage) > 1
                ? " - Seite " + currentPage
                : ""
            }`}</title>
            <meta
              property="og:title"
              content={`${page.ogTitle || page.seoTitle || page.title}${
                currentPage && parseInt(currentPage) > 1
                  ? " - Seite " + currentPage
                  : ""
              }`}
              key="og:title"
            />
          </>
        )}

        {/* Metadescriptions (append page number on active pagination) */}
        <meta
          name="description"
          content={`${page?.description || defaultSeoDescription}${
            currentPage && parseInt(currentPage) > 1
              ? " - Seite " + currentPage
              : ""
          }`}
          key="description"
        />
        <meta
          property="og:description"
          content={`${
            page?.ogDescription || page?.description || defaultSeoDescription
          }${
            currentPage && parseInt(currentPage) > 1
              ? " - Seite " + currentPage
              : ""
          }`}
          key="og:description"
        />

        {router.asPath && (
          <>
            <link
              rel="canonical"
              href={`${
                process.env.NEXT_PUBLIC_FRONTEND_DOMAIN
              }${PagePathUtility.createLinkWithBasepath(
                PagePathUtility.getPathFromUrl(router.asPath)
              )}`}
              key="canonical"
            />
            <meta
              property="og:url"
              content={`${
                process.env.NEXT_PUBLIC_FRONTEND_DOMAIN
              }${PagePathUtility.createLinkWithBasepath(
                PagePathUtility.getPathFromUrl(router.asPath)
              )}`}
              key="og:url"
            />
          </>
        )}

        {page?.ogImage && page.ogImage.length > 0 && (
          <meta
            property="og:image"
            content={ContentApiService.getImageUrl(page.ogImage[0].uid)}
            key="og:image"
          />
        )}

        {((page?.twitterImage && page.twitterImage.length > 0) ||
          (page?.ogImage && page.ogImage.length > 0)) && (
          <meta
            property="twitter:image"
            content={ContentApiService.getImageUrl(
              page?.twitterImage && page.twitterImage.length
                ? page.twitterImage[0].uid
                : page?.ogImage[0].uid
            )}
            key="twitter:image"
          />
        )}

        <meta
          name="keywords"
          content={page?.keywords || defaultSeoKeywords}
          key="keywords"
        />

        <meta
          name="robots"
          content={`${page?.noIndex ? "noindex" : "index"},${
            page?.noFollow ? "nofollow" : "follow"
          }`}
          key="robots"
        />

        <meta name="publisher" content="LTO" />
        <meta name="contributor" content="LTO" />
        <meta property="og:country-name" content="GERMANY" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="LTO-Karriere" />

        <link
          rel="preload"
          href={PagePathUtility.createLinkWithBasepath(
            "/static/icons/sprite.svg"
          )}
          as="image"
        />

        {embedHotjar && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2971335,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
            }}
          />
        )}
      </Head>

      <Header headerNavigationItems={navigationMainHeaderMenu} />

      <div className={`page-content ${pageLayoutClass}`}>
        <ToasterContainer
          placement={PLACEMENT.top}
          overrides={{
            Root: {
              style: () => ({
                marginTop: "6.5rem",
                zIndex: 999,
              }),
            },
            ToastBody: {
              style: ({ $theme }) => ({
                [$theme.mediaQuery.large]: {
                  width: "25vw",
                },
              }),
            },
          }}
        />
        <SnackbarProvider
          overrides={{
            Root: {
              style: () => ({
                marginTop: "6.5rem",
                zIndex: 999,
              }),
            },
            PlacementContainer: {
              style: () => ({
                zIndex: 999,
              }),
            },
          }}
        >
          {children}
        </SnackbarProvider>
      </div>

      {showRegistrationBanner && (
        <StickyInfoBox
          spaceForBottomActions={
            showScrollToTopButton ||
            router.asPath.length === 1 ||
            router.asPath.includes("?wkn")
          }
          iconName="businessman-paper"
          contentText="Registriere Dich auf LTO-Karriere, sieh anhand unseres Matching-Scores,
        welcher Job wirklich zu Dir passt und bewirb dich mit einem Klick."
          buttonText="Jetzt registrieren"
          onButtonClick={() => {
            router.push(publicRuntimeConfig.pagePaths.registrationPage);
          }}
        />
      )}

      {preview && previewData?.type === "link" && (
        <PreviewInfo expires={previewData?.expires} />
      )}
      <PageContextPopupMessages />

      <Footer navigationItems={navigationFooter} />
      {navigationMain && (
        <MainMenu
          mainNavigationItems={navigationMain}
          navigationBottomItems={navigationFooter[2]}
        />
      )}
      <div className={"fixed-page-bottom-actions to-top"}>
        <ScrollToTop hide={!showScrollToTopButton} />
        {(router.asPath.length === 1 ||
          router.asPath.includes("?wkn") ||
          page?.showNewsletterSubscription) && <Subscription />}
        {fixedBottomActions &&
          Array.isArray(fixedBottomActions) &&
          fixedBottomActions.map((ActionComponent, i) => (
            <ActionComponent key={`action_component_${i}`} />
          ))}
      </div>
    </div>
  );
};

export const getLayout = (page, pageProps) => {
  const router = useRouter();

  if (router.isFallback) {
    return (
      <div>
        <CareerLoading text={"Lade Seite..."} />
      </div>
    );
  }

  return <MainLayout {...pageProps}>{page}</MainLayout>;
};

export default MainLayout;
