import Link from "next/link";
import React from "react";

const OptionalLinkWrapper = ({
  href = "",
  className,
  style,
  children,
  target,
  prefetch = false,
  useDefaultLink = false,
  wrapIfEmpty = false,
  wrapClassName = "",
}) => {
  if (href !== "") {
    if (useDefaultLink) {
      return (
        <a href={href} className={className} target={target} style={style}>
          {children}
        </a>
      );
    } else {
      return (
        <Link href={href} prefetch={prefetch}>
          <a className={className} target={target} style={style}>
            {children}
          </a>
        </Link>
      );
    }
  } else if (wrapIfEmpty) {
    return <div className={wrapClassName}>{children}</div>;
  } else {
    return children;
  }
};

export default OptionalLinkWrapper;
