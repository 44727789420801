import React, { useState, useEffect } from "react";
import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import MainNavigation from "../MainNavigation";
import Button from "../Button";

const MainMenu = ({ mainNavigationItems, navigationBottomItems }) => {
  const { t } = useTranslation("common");
  const [isMobile, updateIsMobile] = useState(false);
  const [showMenu, updateShowMenu] = useState(false);

  // active main menu when a window loaded completely
  const activeMainMenu = (e) => {
    if (!showMenu) {
      updateShowMenu(true);
    }
  };

  useEffect(() => {
    updateIsMobile(window && window.innerWidth < 768);

    if (!showMenu) {
      // delay to active main menu
      setTimeout(() => {
        activeMainMenu();
      }, 2000);
    }
  }, []);

  return (
    <div className="main-menu-slideout">
      {!isMobile ? (
        <div
          className={`main-menu-slideout__left ${showMenu && "page_loaded"}`}
        >
          <div>
            <h2 className="slideout__headline">
              <Trans
                i18nKey="common:menu.slogan"
                components={[<br key="-" />]}
              />
            </h2>
            <Link href="/stellenmarkt" prefetch={false} passHref>
              <Button
                layout="hollowForDark"
                text={t("menu.jobMarketButton")}
                icon={false}
              />
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={`main-menu-slideout__right ${showMenu && "page_loaded"}`}>
        <div className={`main-menu-slideout__scroll`}>
          <MainNavigation navigationItems={mainNavigationItems} />
          {isMobile ? (
            <div className={`main-menu-slideout main-menu-slideout__block`}>
              <div>
                <h2 className="slideout__headline">
                  <Trans i18nKey="common:menu.slogan" components={[<br />]} />
                </h2>
                <Link href="/stellenmarkt" prefetch={false}>
                  <Button
                    layout="hollowForDark"
                    text={t("menu.jobMarketButton")}
                    icon={false}
                  />
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={`main-menu__bottom`}>
            <h4>{navigationBottomItems.title}</h4>
            <ul className="no-list">
              {navigationBottomItems.children &&
                Object.values(navigationBottomItems.children).map(
                  (menuItem, i) => (
                    <li key={i}>
                      {/*Internal links*/}
                      {menuItem.nextLinkHref &&
                        !menuItem.nextLinkAs.startsWith("http") && (
                          <Link
                            href={menuItem.nextLinkAs.replace(/\/$/, "")}
                            prefetch={false}
                          >
                            <a className="hover-effect-border">
                              {menuItem.title}
                            </a>
                          </Link>
                        )}
                      {/*External links*/}
                      {menuItem.nextLinkHref &&
                        menuItem.nextLinkAs.startsWith("http") && (
                          <a
                            className="hover-effect-border"
                            target={menuItem.target}
                            href={menuItem.nextLinkAs}
                          >
                            {menuItem.title}
                          </a>
                        )}
                    </li>
                  )
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
