import React, { useState, useEffect } from "react";
import styles from "./Breadcrumb.module.scss";
import OptionalLinkWrapper from "../OptionalLinkWrapper";

const Breadcrumb = ({ items, className }) => {
  const [isMobile, updateIsMobile] = useState(false);

  useEffect(() => {
    updateIsMobile(window && window.innerWidth < 768);
  }, []);

  if (!items || !Array.isArray(items)) return null;

  return (
    <>
      {isMobile && items.length > 3 ? (
        <div className={styles.breadcrumb__scroll}>
          <ul className={`${styles.breadcrumb} ${className}`}>
            <li key={0}>
              <OptionalLinkWrapper
                href={items[items.length - 3].nextLinkAs}
              >{`...`}</OptionalLinkWrapper>
            </li>
            <li key={1}>
              <OptionalLinkWrapper href={items[items.length - 2].nextLinkAs}>
                {items[items.length - 2].title}
              </OptionalLinkWrapper>
            </li>
            <li key={2}>
              <OptionalLinkWrapper href={items[items.length - 1].nextLinkAs}>
                {items[items.length - 1].title}
              </OptionalLinkWrapper>
            </li>
          </ul>
        </div>
      ) : (
        <div className={styles.breadcrumb__scroll}>
          <ul className={`${styles.breadcrumb} ${className}`}>
            {items
              .filter((item) => item.doktype !== 254) // filter folder
              .map((item, index) => (
                <li key={index}>
                  <OptionalLinkWrapper href={item.nextLinkAs}>
                    {item.title}
                  </OptionalLinkWrapper>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Breadcrumb;
