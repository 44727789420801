import ContentApiService from "../modules/cms_content/services/ContentApiService";
import { PageNotFoundError } from "../modules/cms_content/error/PageNotFoundError";

const isServerSide = () => typeof window === "undefined";

const seoPageFound = (searchMeta) =>
  searchMeta.seoPageConfig && searchMeta.seoPageConfig.found;

const getDefaultPageProps = async (nextJsPages, urlPath = "") => {
  const navigationMain = await ContentApiService.fetchMenu("main", nextJsPages);
  const navigationMainHeaderMenu = await ContentApiService.fetchMenu(
    "mainHeaderMenu",
    nextJsPages
  );
  const navigationHeader = await ContentApiService.fetchMenu(
    "header",
    nextJsPages
  );
  const navigationFooter = await ContentApiService.fetchMenu(
    "footer",
    nextJsPages
  );

  let result = {
    pageFound: false,
    navigationMain: navigationMain?.result.menu,
    navigationMainHeaderMenu: navigationMainHeaderMenu?.result.menu,
    navigationHeader: navigationHeader?.result.menu,
    navigationFooter: navigationFooter?.result.menu,
  };

  if (urlPath) {
    try {
      const pageDataResult = await ContentApiService.fetchPage(urlPath);

      pageDataResult.page.rootline = ContentApiService.addLinkParamsToMenuItems(
        pageDataResult.page?.rootline,
        nextJsPages,
        "slug"
      );
      result.pageFound = !!pageDataResult?.page;
      result.page = pageDataResult?.page || null;
      result.pageContents = pageDataResult.pageContent;
    } catch (e) {
      if (e instanceof PageNotFoundError) {
        return {
          ...result,
          page: null,
          pageContents: [],
        };
      } else {
        throw e;
      }
    }
  }

  return result;
};

const compareObject = (object1, object2, ignoreProperties = []) => {
  // Create arrays of property names
  const object1Props = Object.getOwnPropertyNames(object1);
  const object2Props = Object.getOwnPropertyNames(object2);

  // If number of properties is different,
  // objects are not equivalent
  if (object1Props.length !== object2Props.length) {
    return false;
  }

  for (let i = 0; i < object1Props.length; i++) {
    const propName = object1Props[i];

    if (
      Array.isArray(ignoreProperties) &&
      ignoreProperties.includes(propName)
    ) {
      continue;
    }

    if (
      typeof object1[propName] === "object" &&
      object1[propName] &&
      typeof object2[propName] === "object" &&
      object2[propName]
    ) {
      if (
        !compareObject(
          object1[propName],
          object2[propName],
          ignoreProperties[propName] ?? []
        )
      ) {
        return false;
      }
    } else {
      // If values of same property are not equal,
      // objects are not equivalent
      if (object1[propName] !== object2[propName]) {
        return false;
      }
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

const diffObject = (obj1, obj2) => {
  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== "[object Object]") {
    return obj1;
  }

  //
  // Variables
  //

  const diffs = {};
  let key;

  //
  // Methods
  //

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  const arraysMatch = (arr1, arr2) => {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;
  };

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  const compare = (item1, item2, key) => {
    // Get the object type
    const type1 = Object.prototype.toString.call(item1);
    const type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (type2 === "[object Undefined]") {
      diffs[key] = null;
      return;
    }

    // If items are different types
    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    // If an object, compare recursively
    if (type1 === "[object Object]") {
      const objDiff = diffObject(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    // If an array, compare
    if (type1 === "[object Array]") {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === "[object Function]") {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else if (item1 !== item2) {
      diffs[key] = item2;
    }
  };

  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }

  // Return the object of differences
  return diffs;
};

const convertHyphensToCamelCase = (string) =>
  string.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
const convertCamelCaseToHyphens = (string) =>
  string
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => `-${y.toString().toLowerCase()}`)
    .replace(/^_/, "");

const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const lowerCaseFirstLetter = (text) => {
  return text.charAt(0).toLowerCase() + text.slice(1);
};

const isDefaultSearch = (searchArgs, ignoreSorting = true) => {
  if (
    ("filter" in searchArgs && Object.keys(searchArgs.filter).length !== 0) ||
    ("location" in searchArgs &&
      Array.isArray(searchArgs.location) &&
      searchArgs.location.length > 0) ||
    ("query" in searchArgs && searchArgs.query !== "") ||
    (!ignoreSorting && "sort" in searchArgs && searchArgs.sort !== "rel_desc")
  ) {
    return false;
  }
  return true;
};

const shuffle = (items) => {
  let currentIndex = items.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = items[currentIndex];
    items[currentIndex] = items[randomIndex];
    items[randomIndex] = temporaryValue;
  }

  return items;
};

const isEmptyObject = (value) =>
  value && Object.keys(value).length === 0 && value.constructor === Object;

const getDummyRecords = (count) =>
  Array(parseInt(count)).fill({
    isDummy: true,
  });

/**
 * Trim string
 *
 * @param text
 * @param trimChar
 * @returns {string}
 */
const trim = (text, trimChar = " ") => {
  if (Array.isArray(trimChar)) {
    for (const char of trimChar) {
      text = trim(text, char);
    }
  }

  if (text.substring(0, 1) === trimChar) {
    text = text.substring(1, text.length);
  }

  if (text.substring(text.length - 1, text.length) === trimChar) {
    text = text.substring(0, text.length - 1);
  }

  if (
    text.substring(0, 1) === trimChar ||
    text.substring(text.length - 1, text.length) === trimChar
  ) {
    text = trim(text, trimChar);
  }

  return text;
};

const removeHtmlTags = (text) =>
  typeof text === "string" ? text.replace(/(<([^>]+)>)/gi, "") : text;

const categorizeImageRatio = (width, height) => {
  const ratio = width / height;
  let ratioCategory = "rect";
  if (ratio < 1.5 && ratio > 0.8) {
    ratioCategory = "quad";
  } else if (ratio < 0.8) {
    ratioCategory = "quad";
  } else if (ratio < 2.5) {
    ratioCategory = "rect-almost-quad";
  } else if (ratio > 4.5) {
    ratioCategory = "rect-small";
  }
  return ratioCategory;
};

const removeShy = function (html) {
  return html.replace(/\&shy;/gi, "");
};

/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 * */
const loadPolyfills = async function loadPolyfills() {
  if (typeof window.IntersectionObserver === "undefined") {
    return await import("intersection-observer");
  }
};

const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const toggleArrayItem = (array, item) =>
  array.includes(item)
    ? array.filter((i) => i !== item) // remove item
    : [...array, item]; // add item

const toggleArrayItemWithRecords = (array, item, idField = "uid") =>
  array.find((arrayItem) => arrayItem[idField] === item[idField])
    ? array.filter((i) => i[idField] !== item[idField]) // remove item
    : [...array, item]; // add item

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = (item) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

const scrollToElement = (element, offset = 70) => {
  if (!element) {
    return;
  }
  const { top } = element.getBoundingClientRect() || {
    top: null,
  };
  if (top) {
    window.scrollTo({
      top: window.pageYOffset + top - offset,
      behavior: "smooth",
    });
  }
};

const getCookie = (name) => {
  // Add the = sign
  name = name + "=";

  // Get the decoded cookie
  const decodedCookie = decodeURIComponent(document.cookie);

  // Get all cookies, split on ; sign
  const cookies = decodedCookie.split(";");

  // Loop over the cookies
  for (let i = 0; i < cookies.length; i++) {
    // Define the single cookie, and remove whitespace
    const cookie = cookies[i].trim();

    // If this cookie has the name of what we are searching
    if (cookie.indexOf(name) === 0) {
      // Return everything after the cookies name
      return cookie.substring(name.length, cookie.length);
    }
  }
};

/**
 * Native scrollTo with callback
 * @param offsetOrFunction - offset to scroll to or function to calc offset
 * @param callback - callback function triggered after scroll position reached
 */
const scrollTo = (offsetOrFunction, callback) => {
  const offset =
    typeof offsetOrFunction === "function"
      ? offsetOrFunction()
      : offsetOrFunction;

  let fixedOffset = offset.toFixed();
  const onScroll = function () {
    // we can check position has moved (because of lazy loading...)
    if (typeof offsetOrFunction === "function") {
      const newDestinationOffset = offsetOrFunction();
      if (Math.abs(fixedOffset - newDestinationOffset.toFixed()) > 10) {
        window.scrollTo({
          top: newDestinationOffset,
          behavior: "smooth",
        });
        fixedOffset = newDestinationOffset.toFixed();
      }
    }

    // safari 1 pixel bug
    if (
      Math.abs(
        parseInt(window.pageYOffset.toFixed()) - parseInt(fixedOffset)
      ) <= 1
    ) {
      window.removeEventListener("scroll", onScroll);
      callback();
    }
  };

  window.addEventListener("scroll", onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: "smooth",
  });
};

/**
 * Decode htmlentities !client only!
 * @param str
 * @returns {string|*}
 */
const decodeHtmlCharCodes = (str) => {
  if (isServerSide()) {
    return str;
  }
  const textarea = document.createElement("textarea");
  textarea.innerHTML = str;
  return textarea.value;
};

const loadScriptByURL = (id, url, callback, additionalProps = {}) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };

    if (additionalProps.async) {
      script.async = true;
    }
    if (additionalProps.defer) {
      script.defer = true;
    }
    if (additionalProps.data) {
      for (const dataKey in additionalProps.data) {
        script.dataset[dataKey] = additionalProps.data[dataKey];
      }
    }

    document.body.appendChild(script);
    return script;
  }

  if (existingScript && callback) callback();
  return existingScript;
};

export default {
  isServerSide,
  seoPageFound,
  compareObject,
  diffObject,
  isDefaultSearch,
  getDefaultPageProps,
  convertHyphensToCamelCase,
  convertCamelCaseToHyphens,
  shuffle,
  getDummyRecords,
  isEmptyObject,
  trim,
  removeHtmlTags,
  categorizeImageRatio,
  removeShy,
  loadPolyfills,
  isElementInViewport,
  toggleArrayItem,
  toggleArrayItemWithRecords,
  capitalizeFirstLetter,
  lowerCaseFirstLetter,
  isObject,
  mergeDeep,
  scrollTo,
  decodeHtmlCharCodes,
  getCookie,
  scrollToElement,
  loadScriptByURL,
};
