
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { wrapper } from "../store";
import React, { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";

import { Provider, signIn, useSession } from "next-auth/client";
import { Provider as StyletronProvider } from "styletron-react";
import {
  createTheme,
  lightThemePrimitives,
  BaseProvider,
  LocaleProvider,
} from "baseui";

import getConfig from "next/config";

import "../assets/scss/style.scss";
import NProgress from "nprogress";
import { styletron } from "../styletron";
import { getLayout as getDefaultLayout } from "../layouts/DefaultPageLayout";

import "intersection-observer";
import basewebI18n from "../basewebI18n";
import CareerLoading from "../components/CareerLoading/CareerLoading";
import PageContextProvider from "../modules/cms_content/context/PageContext";
import EmployerManagementContextProvider from "../modules/employer/context/EmployerManagementContext";
import PropTypes from "prop-types";

const Auth = ({ userTypes, children }) => {
  const { publicRuntimeConfig } = getConfig();
  const [session, loading] = useSession();
  const [isUser, setIsUser] = useState();
  const router = useRouter();
  const firstUserType = userTypes[0];

  useEffect(() => {
    if (loading) return; // Do nothing while loading

    let isUser = false;
    switch (firstUserType) {
      case "candidate":
        isUser = !!session?.user;
        break;
      case "employer":
        isUser =
          !!session?.accessToken &&
          Date.now() < parseInt(session?.accessTokenExpires) * 1000;
        break;
    }

    setIsUser(isUser);

    if (!isUser) {
      // If not authenticated, force log in
      router.push(
        publicRuntimeConfig.pagePaths[
          `${firstUserType.toLocaleLowerCase()}Login`
        ]
      );
    }
  }, [isUser, loading, session]);

  if (isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <CareerLoading text={"Prüfe Benutzerberechtigungen..."} />;
};

Auth.propTypes = {
  userTypes: PropTypes.arrayOf(PropTypes.oneOf(["candidate", "employer"])),
};

export function reportWebVitals(metric) {
  if (metric.name === "Next.js-route-change-to-render") {
    window.dataLayer.push({
      event: "pageLoadTime",
      pageLoadTimeDataLayer: metric.value,
    });
  }
}

const MyApp = ({ Component, pageProps, err }) => {
  pageProps = {
    ...pageProps,
    fixedBottomActions: Component.fixedBottomActions || [],
  };

  useEffect(() => {
    if (!navigator.userAgent?.includes("ltoapp")) {
      NProgress.configure({ showSpinner: false, parent: ".page-header" });
    }

    // redirect empty / useless anchor links
    if (document.URL.endsWith("#")) {
      const urlSplit = document.URL.split("#");
      if (1 in urlSplit && ["", "!"].includes(urlSplit[1])) {
        location.href = urlSplit[0];
      }
    }
  }, []);

  useEffect(() => {
    /**
     * control progress bar by router events
     */
    const handleRouteChangeForProgressBar = () => {
      if (
        document.querySelector(".page-header") &&
        !navigator.userAgent?.includes("ltoapp")
      ) {
        NProgress.start();
      }
    };

    const handleRouteChangeForProgressBarDone = () => {
      if (
        document.querySelector(".page-header") &&
        !navigator.userAgent?.includes("ltoapp")
      ) {
        NProgress.done();
      }
    };

    Router.events.on("routeChangeStart", handleRouteChangeForProgressBar);
    Router.events.on(
      "routeChangeComplete",
      handleRouteChangeForProgressBarDone
    );
    Router.events.on("routeChangeError", handleRouteChangeForProgressBarDone);

    return () => {
      // unbind route change for progress bar
      Router.events.off("routeChangeStart", handleRouteChangeForProgressBar);
      Router.events.off(
        "routeChangeComplete",
        handleRouteChangeForProgressBarDone
      );
      Router.events.off(
        "routeChangeError",
        handleRouteChangeForProgressBarDone
      );
    };
  }, [Router.events]);

  const primitives = {
    ...lightThemePrimitives,
    red: "#df1051", // hot pink
    blue: "#440DEB", // hot pink
  };
  const overrides = {
    colors: {
      // primaryA: primitives.red,
      // primaryB: primitives.blue,
      buttonPrimaryFill: primitives.red,
      toastPositiveBackground: primitives.blue,
      toastNegativeBackground: primitives.red,
    },
  };

  const customTheme = createTheme(primitives, overrides);

  const localeOverrideDe = basewebI18n;

  const getLayout =
    Component.getLayout ||
    ((page, pageProps) => getDefaultLayout(page, pageProps));

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <Provider
      session={pageProps.session}
      options={{
        clientMaxAge: 60 * 60, // Re-fetch session if cache is older than 1 hour
        keepAlive: 5 * 60, // Send keepAlive message every 5 minutes
        basePath: `${process.env.BASE_PATH}/api/auth`,
      }}
    >
      <StyletronProvider value={styletron}>
        <BaseProvider theme={customTheme}>
          <LocaleProvider locale={localeOverrideDe}>
            <PageContextProvider
              page={pageProps.page}
            >
              {Component.auth
                ? getLayout(
                    <Auth userTypes={Component.auth.userTypes || ["candidate"]}>
                      <EmployerManagementContextProvider>
                        <Component {...pageProps} err={err} />
                      </EmployerManagementContextProvider>
                    </Auth>,
                    pageProps
                  )
                : getLayout(<Component {...pageProps} err={err} />, pageProps)}
            </PageContextProvider>
          </LocaleProvider>
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  );
};

const __Page_Next_Translate__ = wrapper.withRedux(MyApp);


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  