import React, { useState } from "react";
import styles from "./PreviewInfo.module.scss";
import Icon from "../Icon";
import Link from "next/link";
import PagePathUtility from "../../libs/PagePathUtility";

const PreviewInfo = ({ expires }) => {
  const sessionEnd = new Date(parseInt(expires));

  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className={styles.previewInfo}>
      <span className={styles.previewTitle}>
        Vorschaumodus{" "}
        <a
          href={PagePathUtility.createLinkWithBasepath("/api/clearPreview")}
          className={styles.clearLink}
        >
          [verlassen]
        </a>
      </span>
      {expires && (
        <>
          <div>
            <div
              className={styles.infoToogleTitle}
              onClick={() => setShowInfo((showInfo) => !showInfo)}
            >
              <span>Hinweis</span>
              <Icon name="expand" size="medium" />
            </div>
            <div
              className={`${styles.infoToogleText} ${
                showInfo ? styles.open : ""
              }`}
            >
              <span className={styles.previewDescription}>
                Gültig bis:{" "}
                {sessionEnd.toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}{" "}
                Uhr
              </span>
              <p>
                Der verwendete Vorschaulink erzeugt eine Session, in deren
                Gültigkeitszeitraum der Vorschaumodus aktiv ist.
                <br />
                Nach Ablauf verwenden Sie bitte erneut den zur Verfügung
                gestellten Vorschaulink.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PreviewInfo;
