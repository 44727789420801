import { fetchData } from "../../../../libs/Fetch";
import PagePathUtility from "../../../../libs/PagePathUtility";

const FETCH_USER_DATA_URL = "";

async function fetch(
  url = "",
  method = "GET",
  data = {},
  additionalHeaders = {},
  convertResponseToJson = true
) {
  // Default options are marked with *

  let options = {
    // mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "content-type": "application/json; charset=utf-8",
      ...additionalHeaders,
    },
    referrerPolicy: "no-referrer",
  };

  if (!url.startsWith("http")) {
    url = `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${url}`;
  }

  return fetchData(url, data, method, convertResponseToJson, options);
}

const handleErrors = (res) => {
  if (!res.ok) {
    return res.json().then((json) => {
      throw json;
    });
  }
  return res;
};

const UserApiFrontendService = {
  /**
   * Fetch userdata
   */
  fetchUserRegistration: (userData, csrfToken) => {
    const url = PagePathUtility.createLinkWithBasepath("/api/v1/user/register");
    return fetch(url, "POST", userData);
  },
  /**
   * Fetch userdata
   */
  fetchUserdata: () => {
    const url = PagePathUtility.createLinkWithBasepath("/api/v1/user/data");
    return fetch(url);
  },
  /**
   * Fetch update
   */
  fetchUserdataUpdate: (userData, csrfToken) => {
    const url = PagePathUtility.createLinkWithBasepath("/api/v1/user/update");
    return fetch(url, "POST", userData, {
      "CSRF-Token": csrfToken,
    });
  },
  /**
   * Fetch delete
   */
  fetchUserDelete: (csrfToken) => {
    const url = PagePathUtility.createLinkWithBasepath("/api/v1/user/delete");
    return fetch(
      url,
      "POST",
      {},
      {
        "CSRF-Token": csrfToken,
      }
    );
  },
  /**
   * Fetch update
   */
  fetchJobApplyFile: (jobUid) => {
    const url = PagePathUtility.createLinkWithBasepath(
      `/api/v1/jobApply/${jobUid || 0}/show`
    );
    return fetch(
      `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${url}`,
      "GET",
      {},
      {},
      false
    );
  },
  /**
   * Fetch update
   */
  fetchJobApply: (jobUid, locationIds, activityIds) => {
    const url = PagePathUtility.createLinkWithBasepath(
      `/api/v1/jobApply/${jobUid || 0}/send`
    );
    return fetch(`${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}${url}`, "POST", {
      locations: locationIds,
      activities: activityIds,
    });
  },

  /**
   * Fetch user update password
   * @param currentPassword
   * @param newPassword
   */
  fetchUserUpdatePassword: (currentPassword, newPassword) => {
    const url = PagePathUtility.createLinkWithBasepath(
      "/api/v1/user/updatePasswordBySession"
    );

    const request = {
      currentPassword,
      newPassword,
    };

    return fetch(url, "POST", request);
  },

  /**
   * Fetch user update password
   * @param email
   */
  fetchSendForgotPasswordEmail: (email) => {
    const url = PagePathUtility.createLinkWithBasepath(
      "/api/v1/user/sendForgotPasswordEmail"
    );

    const request = {
      email,
    };

    return fetch(url, "POST", request);
  },

  /**
   * Fetch confirm update password
   * @param uid
   * @param token
   * @param password
   */
  fetchConfirmEmailAgain: (email) => {
    const url = PagePathUtility.createLinkWithBasepath(
      "/api/v1/user/sendConfirmationEmail"
    );

    const request = {
      email,
    };

    return fetch(url, "POST", request);
  },

  /**
   * Fetch confirm update password
   * @param uid
   * @param token
   * @param password
   */
  fetchConfirmUpdatePassword: (uid, token, password) => {
    const url = PagePathUtility.createLinkWithBasepath(
      "/api/v1/user/updatePasswordByToken"
    );

    const request = {
      uid,
      token,
      password,
    };

    return fetch(url, "POST", request);
  },
};

export default UserApiFrontendService;
