import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, SIZE } from "baseui/modal";
import { PageContext } from "../../../context/PageContext";
import useWindowSize from "../../../../../hooks/useWindowSize";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icon";
import { useStyletron } from "baseui";

const PageContextPopupMessages = () => {
  const { popupMessage, clearPopupMessage } = useContext(PageContext);
  const [css, theme] = useStyletron();

  const [isMessageModalOpen, setMessageModalOpen] = useState(
    !!popupMessage?.text
  );
  const windowSize = useWindowSize();

  useEffect(() => {
    if (popupMessage?.text) {
      setMessageModalOpen(true);
    }
  }, [popupMessage]);

  if (!popupMessage?.text) {
    return null;
  }

  return (
    <Modal
      onClose={close}
      isOpen={isMessageModalOpen}
      size={popupMessage?.size ? popupMessage?.size : SIZE.auto}
      closeable={false}
      overrides={{
        Root: {
          style: {
            zIndex: "300",
          },
        },
        DialogContainer: {
          style: {
            paddingTop: "6rem",
          },
        },
        Dialog: {
          style: {
            maxWidth: "1000px",
          },
        },
      }}
      autoFocus={popupMessage.autoFocus || true}
    >
      <ModalHeader>
        <div className="grid-items-left">
          <img
            src={PagePathUtility.createLinkWithBasepath(
              "/static/img/logo_lto_karriere_black.svg"
            )}
            width={100}
            className="show-for-medium"
            alt="logo lto karriere"
          />
          {popupMessage.title && (
            <h4
              className={`mg-bottom-0 ${css({
                [theme.mediaQuery.medium]: {
                  position: "relative",
                  marginLeft: "2rem",
                  ":before": {
                    content: "''",
                    position: "absolute",
                    left: "-1rem",
                    height: "1.2em",
                    borderLeft: "rgb(226, 226, 226) 1px solid",
                  },
                },
              })}`}
            >
              {popupMessage.title}
            </h4>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        {typeof popupMessage.text === "string"
          ? popupMessage.text
          : popupMessage.text()}
      </ModalBody>
      {!popupMessage.hideButtons && (
        <ModalFooter>
          <div
            className={`button-group ${
              windowSize.width < 768
                ? "button-group--left"
                : popupMessage.buttonPosition === "center"
                ? "button-group--center"
                : "button-group--right"
            }`}
          >
            {popupMessage.additionalButtons &&
              Array.isArray(popupMessage.additionalButtons) &&
              popupMessage.additionalButtons.length > 0 &&
              popupMessage.additionalButtons.map((buttonConfig) => (
                <Button
                  onClick={() => {
                    setMessageModalOpen(false);
                    clearPopupMessage();
                    buttonConfig.onClick && buttonConfig.onClick();
                  }}
                  layout="secondary"
                  startEnhancer={() => (
                    <Icon name={buttonConfig.icon} size={"default"} />
                  )}
                  text={buttonConfig.label}
                  key={buttonConfig.label}
                />
              ))}
            {!popupMessage.hideCancelButton && (
              <Button
                onClick={() => {
                  setMessageModalOpen(false);
                  clearPopupMessage();
                  popupMessage.onCancel && popupMessage.onCancel();
                }}
                layout="secondary"
                text={
                  popupMessage.cancelButtonTextOverride
                    ? popupMessage.cancelButtonTextOverride
                    : "Abbrechen"
                }
              />
            )}
            <Button
              onClick={() => {
                setMessageModalOpen(false);
                clearPopupMessage();
                popupMessage.onSuccess && popupMessage.onSuccess();
              }}
              layout="primary"
              text={
                popupMessage.submitButtonTextOverride
                  ? popupMessage.submitButtonTextOverride
                  : "Alles klar"
              }
            />
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default PageContextPopupMessages;
