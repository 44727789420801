import Button from "../../../../components/Button";
import styles from "./StickyInfoBox.module.scss";
import Icon from "../../../../components/Icon";
import PropTypes from "prop-types";
import { getStorage } from "../../../../libs/StorageUtility";
import { useEffect, useState } from "react";

const StickyInfoBox = ({
  spaceForBottomActions,
  iconName,
  contentText,
  buttonText,
  onButtonClick,
}) => {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const storage = getStorage();

  useEffect(() => {
    const storage = getStorage();

    if (storage) {
      const hideInfoTeaser = storage.getItem("infoTeaser");
      if (!hideInfoTeaser) {
        setShowInfoBox(true);
      }
    }
  }, []);

  const handleCloseClick = () => {
    storage.setItem("infoTeaser", true);
    setShowInfoBox(false);
  };

  const handleButtonClick = () => {
    onButtonClick && onButtonClick();
    handleCloseClick();
  };

  if (!showInfoBox) {
    return null;
  }

  return (
    <div className={`${styles.container} bg-red`}>
      <div className={styles.close}>
        <Icon name="close" size="small" onClick={handleCloseClick} />
      </div>
      <Icon name={iconName} size="large" otherClass={styles.icon} />
      <p className={styles.text}>{contentText}</p>
      <div className={styles.buttonContainer}>
        <Button
          layout="hollowForDark"
          onClick={handleButtonClick}
          customClassName={styles.button}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

StickyInfoBox.propTypes = {
  spaceForBottomActions: PropTypes.bool,
};

export default StickyInfoBox;
