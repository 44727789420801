/**
 * Allow set / get value by string path
 *
 * @param obj
 * @param path
 * @param value
 * @param defaultValue
 * @returns {*}
 */
export const objectSetGet = (obj, path, value, defaultValue = {}) => {
  if (typeof path == "string") {
    return objectSetGet(
      obj,
      path.replace(/\[(\d*)\]/gm, `.$1`).split("."),
      value,
      defaultValue
    );
  } else if (path.length === 1) {
    if (value !== undefined) {
      // set value and return
      return (obj[path[0]] = value);
    } else {
      // return property
      return obj[path[0]];
    }
  } else if (path.length === 0) {
    return obj;
  } else {
    if (!obj[path[0]]) {
      obj[path[0]] = {};
    }
    return objectSetGet(obj[path[0]], path.slice(1), value, defaultValue);
  }
};

export const objectGet = (obj, path) => objectSetGet(obj, path);

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const checkIsValidValueForFieldPath = (
  fieldRequirements,
  fieldPath,
  value
) => {
  const fieldConfig = getValueByFieldPath(fieldPath, fieldRequirements);

  let isValid = true;
  // do some type specific validations
  if (fieldConfig.type === "array" && fieldConfig.typeConfig) {
    if (fieldConfig.typeConfig.min > value.length) {
      isValid = false;
    }
  }

  // default check in case it's not set as invalid
  if (isValid && isEmptyValue(value)) {
    isValid = false;
  }
  return isValid;
};

export const checkIsRequiredFieldByFieldPath = (
  fieldRequirements,
  fieldPath,
  userData
) => {
  if (!fieldPath || !fieldRequirements || !userData) {
    return false;
  }
  const [recordType, fieldName] = fieldPath.split(".");
  return checkIsRequiredField(
    fieldRequirements?.[recordType]?.[fieldName],
    userData
  );
};

export const checkIsRequiredField = (fieldRequirementConfig, userData) => {
  let isRequiredDependency = true;
  const fieldPathDependencies = fieldRequirementConfig?.dependencies;
  if (!fieldPathDependencies) {
    // no dependencies found
    return false;
  } else {
    for (const dependencyRecordType in fieldPathDependencies) {
      const dependenciesOfRecordType =
        fieldPathDependencies[dependencyRecordType];
      // check dependencies of record type (user / candidate)
      for (const dependencyFieldname in dependenciesOfRecordType) {
        const valueToBeIncluded = dependenciesOfRecordType[dependencyFieldname];
        const currentValue =
          userData?.[dependencyRecordType]?.[dependencyFieldname];
        if (!valueToBeIncluded.includes(currentValue)) {
          // dependency not matched
          isRequiredDependency = false;
        }
      }
    }
  }
  return isRequiredDependency;
};

export const getValueByFieldPath = (fieldPath, userData) => {
  const [recordType, fieldName] = fieldPath.split(".");
  return userData?.[recordType]?.[fieldName];
};

export const extractDataByFieldPath = (fieldPath, userData) => {
  const [recordType, fieldName] = fieldPath.split(".");
  return {
    recordType,
    fieldName,
    value: userData[recordType]?.[fieldName],
  };
};

export const isEmptyValue = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else if (value && value.constructor === Object) {
    return Object.keys(value).length === 0;
  } else {
    return !value;
  }
};

export const removeItemByValue = (array, value) => {
  const index = array.indexOf(value);
  if (index !== -1) {
    const newValue = [...array];
    newValue.splice(index, 1);
    return newValue;
  }
  return array;
};

export const addUniqueItem = (array, value) => {
  if (!array.includes(value)) {
    return [...array, value];
  }
  return array;
};

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const sanitizeString = (str) => {
  if (!str) {
    return "";
  }
  str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "");
  return str.trim();
};

export const getRangeOfScoreClass = (scoreClasses, scoreClass) => {
  let minValue = null;
  let maxValue = null;
  if (scoreClass) {
    const indexOfCurrentScoreClass =
      Object.keys(scoreClasses).indexOf(scoreClass);
    const nextScoreClass =
      Object.values(scoreClasses)[indexOfCurrentScoreClass - 1];

    minValue = parseFloat(scoreClass.replace("gt_", ""));
    maxValue = parseFloat(nextScoreClass?.key?.replace("gt_", ""));
  }
  return {
    minValue,
    maxValue: maxValue || 18.0,
  };
};

export const replaceByUidOrAppend = (newItem, items) => {
  const indexToReplace = items.findIndex((item) => item.uid === newItem.uid);
  if (indexToReplace >= 0) {
    items[indexToReplace] = newItem;
  } else {
    items.push(newItem);
  }
  return items;
};
