import Icon from "../../Icon";
import { Avatar } from "baseui/avatar";
import UserApiService from "../../../modules/user/services/UserApiService";
import Link from "next/link";
import { signOut } from "next-auth/client";
import { showNotification } from "../../../libs/NotificationUtility";
import { MESSAGE_TYPES } from "../../../libs/Types";
import React from "react";
import { useRouter } from "next/router";
import getConfig from "next/config";

const EmployerUserMenu = ({ session }) => {
  const { publicRuntimeConfig } = getConfig();
  return (
    <>
      {/*<div className="page-header__login-image">*/}
      {/*  <Avatar*/}
      {/*    size={`80px`}*/}
      {/*    src={*/}
      {/*      session?.user?.profileImage*/}
      {/*        ? UserApiService.getUserFileUrl(session.user.profileImage)*/}
      {/*        : ""*/}
      {/*    }*/}
      {/*    name={session.user?.name || ""}*/}
      {/*  />*/}
      {/*</div>*/}
      {session?.user?.firstName && (
        <p className="text-small text-center">{session.user.firstName} {session.user.lastName}</p>
      )}
      {session?.user?.email && (
        <p className="text-small text-center">{session.user.email}</p>
      )}
      <hr />
      <ul className="no-list link-list">
        <li>
          <Link href={publicRuntimeConfig.pagePaths.employerEditRoot}>
            <a>
              <Icon name="login2" />
              Arbeitgeber verwalten
            </a>
          </Link>
        </li>
        {/*<li>*/}
        {/*  <Link href={publicRuntimeConfig.pagePaths.userProfileEdit}>*/}
        {/*    <a>*/}
        {/*      <Icon name="settings-slider" />*/}
        {/*      Account*/}
        {/*    </a>*/}
        {/*  </Link>*/}
        {/*</li>*/}
        <li>
          <a
            className="logout"
            onClick={() =>
              signOut().then(() =>
                showNotification(
                  "Erfolgreich abgemeldet",
                  MESSAGE_TYPES.SUCCESS
                )
              )
            }
          >
            <>
              <Icon name="logout" size="default" />
              Abmelden
            </>
          </a>
        </li>
      </ul>
    </>
  );
};

export default EmployerUserMenu;
