import types from "./types";
import initialStateDefault from "./initialState";
import { HYDRATE } from "next-redux-wrapper";

export const jobsReducer = (state = initialStateDefault, action) => {
  switch (action.type) {
    case HYDRATE:
      /**
       * Use static server generated job list only if
       * - no active search exists and no user specific search history set (if history exists client side job list fetch)
       * - is seo page request
       */
      if (
        (!state.activeSearch &&
          state.searchHistory.length === 0 &&
          state.jobsHistory.length === 0) ||
        action.payload.jobSearch.isSeoPage
      ) {
        let newState = {
          ...state,
          ...action.payload.jobSearch,
        };
        newState.initialized = state.initialized;
        // newState.activeSearch = state.activeSearch;
        // newState.searchHistory = state.searchHistory;
        // newState.nextSearch = state.nextSearch;

        return newState;
      }

      // Attention! This will overwrite client state! Real apps should use proper reconciliation.
      return state;
    case types.JOBMARKET_INITIALIZED:
      return {
        ...state,
        initialized: action.state,
      };
    case types.FETCH_JOB_SEARCH_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.SET_SORTING:
      return {
        ...state,
        search: {
          ...state.search,
          args: {
            ...state.search.args,
            sort: `${action.payload.sortType}_${action.payload.sortDirection}`,
          },
        },
      };
    case types.SET_SEARCH_QUERY:
      return {
        ...state,
        search: {
          ...state.search,
          args: {
            ...state.search.args,
            query: action.payload,
          },
        },
      };
    case types.SET_SEARCH_LOCATION:
      return {
        ...state,
        search: {
          ...state.search,
          args: {
            ...state.search.args,
            location: action.payload,
          },
        },
      };
    case types.SET_SEARCH_LOCATION_RADIUS:
      return {
        ...state,
        search: {
          ...state.search,
          args: {
            ...state.search.args,
            radius: action.payload,
          },
        },
      };
    case types.SET_SEARCH_FILTER:
      const searchFilter = action.payload.filter;
      return {
        ...state,
        search: {
          ...state.search,
          args: {
            ...state.search.args,
            filter: searchFilter,
          },
        },
      };
    case types.TOGGLE_SEARCH_FILTER:
      let currentFacetFilter = state.search.args.filter || [];
      const valueExists =
        action.payload.key in currentFacetFilter &&
        Array.isArray(currentFacetFilter[action.payload.key]) &&
        currentFacetFilter[action.payload.key].includes(action.payload.value);
      if (valueExists) {
        // remove filter item
        currentFacetFilter = {
          ...currentFacetFilter,
          [action.payload.key]: currentFacetFilter[action.payload.key].filter(
            (item) => {
              if (typeof action.payload.value === "number") {
                return parseInt(item) !== parseInt(action.payload.value);
              } else {
                return item !== action.payload.value;
              }
            }
          ),
        };

        if (currentFacetFilter[action.payload.key].length === 0) {
          // delete filter type if no values
          delete currentFacetFilter[action.payload.key];
        }
      } else {
        // add filter item
        const currentFacetItems = currentFacetFilter[action.payload.key] || [];
        currentFacetFilter = {
          ...currentFacetFilter,
          [action.payload.key]: [...currentFacetItems, action.payload.value],
        };
      }

      return {
        ...state,
        search: {
          ...state.search,
          args: {
            ...state.search.args,
            filter: currentFacetFilter,
          },
        },
      };
    case types.FETCH_JOB_SEARCH_SUCCESS:
      return {
        ...state,
        page: action.data.args.page,
        pagesFound: action.data.args.numFoundPages,
        activeSearch: !action.isDefaultSearch,
        pending: false,
        jobs:
          action.data.args.page === 1
            ? action.data.result.solr.response.docs
            : [...state.jobs, ...action.data.result.solr.response.docs],
        facets: action.data.result.facets,
        jobsFound: action.data.result.solr.response.numFound,
        isSsr: typeof window === "undefined",
        isSeoPage: false,
        search: {
          args: action.searchArgs,
          meta: {
            type: "default",
            title: action.title,
            timestamp: action.timestamp,
            queriesFormatted: action.data.args?.queries,
            locationFormatted: action.data.args?.location_current,
            geo: action.data.args?.geo,
          },
        },
      };
    case types.FETCH_JOB_SEO_SEARCH_SUCCESS:
      return {
        ...state,
        page: action.data.args.page,
        pagesFound: action.data.args.numFoundPages,
        activeSearch: true,
        pending: false,
        jobs:
          action.data.args.page === 1
            ? action.data.result.solr.response.docs
            : [...state.jobs, ...action.data.result.solr.response.docs],
        facets: action.data.result.facets,
        jobsFound: action.data.result.solr.response.numFound,
        isSsr: typeof window === "undefined",
        isSeoPage: true,
        search: {
          ...state.search,
          args: {
            query: action.data.args?.query || "",
            location: action.data.args?.location || "",
            radius: action.data.args?.radius || 50,
            filter: action.data.args?.filter || [],
          },
          meta: {
            type: "seo",
            title: action.title,
            timestamp: action.timestamp,
            queriesFormatted: action.data.args?.queries,
            locationFormatted: action.data.args?.location_current,
            seoPageData: action.data.args?.seoPageOrigData || {},
            seoPageConfig: action.data.args?.seoPage,
            seoSearchFormTitle:
              action.data.args?.searchPluginSettings?.searchTitle || "",
            seoSearchFormHide:
              action.data.args?.searchPluginSettings?.hideTopSearchForm ||
              false,
            geo: action.data.args?.geo,
          },
        },
      };
    case types.FETCH_JOB_SEARCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error?.message,
      };
    case types.RESET_SEARCH:
      return {
        ...state,
        activeSearch: false,
        lastSearch: null,
        facets: [],
        pending: false,
        error: action.error,
      };
    case types.MOVE_LAST_SEARCH_TO_HISTORY:
      const searchHistory = [state.search, ...state.searchHistory];
      if (searchHistory.length >= 4) {
        searchHistory.pop();
      }

      return {
        ...state,
        searchHistory: searchHistory,
      };
    case types.MOVE_SEARCH_HISTORY_ITEM_TO_TOP:
      let historyItemToMove = state.searchHistory.splice(action.index, 1);

      if (historyItemToMove.length === 1) {
        historyItemToMove = historyItemToMove[0];
      }
      state.searchHistory.unshift(historyItemToMove);

      return {
        ...state,
      };
    case types.MOVE_JOB_TO_HISTORY:
      const jobsHistory = [action.job, ...state.jobsHistory];
      if (jobsHistory.length >= 4) {
        jobsHistory.pop();
      }

      return {
        ...state,
        jobsHistory: jobsHistory,
      };
    case types.SAVE_BOOSTING:
      return {
        ...state,
        boosting: action.boosting,
      };
    case types.SET_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: action.searchHistory,
      };
    case types.SET_JOBS_HISTORY:
      return {
        ...state,
        jobsHistory: action.jobsHistory,
      };
    case types.SEARCH_HISTORY_INITIALIZED:
      return {
        ...state,
        searchHistoryInitialized: action.payload,
      };
    case types.NEWSLETTER_SUBSCRIBE_CLEAR:
      return {
        ...state,
        newsletter: {
          newsletterFormError: false,
          newsletterFormMessages: [],
          newsletterFormErrors: [],
        },
      };
    case types.NEWSLETTER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        newsletter: {
          newsletterFormError: false,
          newsletterFormMessages: action.messages,
        },
      };
    case types.NEWSLETTER_SUBSCRIBE_FAILED:
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          newsletterFormError: true,
          newsletterFormErrors: action.errors,
          newsletterFormMessages: [],
        },
      };
    case types.SET_LAST_VISIT:
      return {
        ...state,
        lastVisit: action.timestamp,
      };
    case types.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return { ...state };
  }
};

export const isJobmarketInitialized = (state) => state.jobSearch.initialized;
export const getJobs = (state) => state.jobSearch.jobs;
export const getJobsPending = (state) => state.jobSearch.pending;
export const getError = (state) => state.jobSearch.error;
export const getSearch = (state) => state.jobSearch.search;
export const getSearchMeta = (state) => state.jobSearch.search.meta;
export const getSearchArgs = (state) => state.jobSearch.search.args;
export const isActiveSeoSearch = (state) =>
  !!state.jobSearch.search?.meta.seoPageConfig;
export const getFacets = (state) => state.jobSearch.facets;
export const getJobsFound = (state) => state.jobSearch.jobsFound;
export const isActiveSearch = (state) => state.jobSearch.activeSearch;
export const getSearchHistory = (state) => state.jobSearch.searchHistory;
export const getSearchHistoryInitialized = (state) =>
  state.jobSearch.searchHistoryInitialized;
export const getJobsHistory = (state) => state.jobSearch.jobsHistory;
export const getBoosting = (state) => state.jobSearch.boosting;
export const getCurrentPage = (state) => state.jobSearch.page;
export const getPagesFound = (state) => state.jobSearch.pagesFound;
export const getNewsletterInfo = (state) => state.jobSearch.newsletter;
export const getLastVisit = (state) => state.jobSearch.lastVisit;
export const getUser = (state) => state.jobSearch.user;
