import React from "react";
import { MESSAGE_TYPES } from "./Types";
import { KIND, toaster } from "baseui/toast";
import Button from "../components/Button";
import Icon from "../components/Icon";

const getNotificationKind = (notificationType) => {
  const typeColors = {
    [MESSAGE_TYPES.SUCCESS]: KIND.positive,
    [MESSAGE_TYPES.ALERT]: KIND.negative,
  };

  return typeColors[notificationType] || KIND.info;
};

const getNotificationIcon = (notificationType) => {
  const typeColors = {
    [MESSAGE_TYPES.SUCCESS]: "validation",
    [MESSAGE_TYPES.ALERT]: "alert-triangle",
  };

  return typeColors[notificationType] || "validation";
};

/**
 * show message via snackbar
 * @param message
 * @param type
 * @param options
 */
export const showNotification = (
  message,
  type = MESSAGE_TYPES.SUCCESS,
  options = {}
) => {
  const { actionMessage, actionOnClick, autoHideDuration = 5000 } = options;

  let content = (
    <div className="notification">
      <div className="notification--content content-center">
        <div className="notification--icon">
          <Icon name={getNotificationIcon(type)} size="default" />
        </div>
        <div className="notification--text text-default">{message}</div>
      </div>
      {actionMessage && actionOnClick && (
        <Button
          customClassName="notification--button"
          onClick={actionOnClick}
          layout="hollowForDark"
          text={actionMessage}
        />
      )}
    </div>
  );

  toaster.show(content, {
    kind: getNotificationKind(type),
    autoHideDuration,
    overrides: {
      InnerContainer: {
        style: { width: "100%" },
      },
    },
  });
};
