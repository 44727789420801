import React from "react";
import Link from "next/link";
import PagePathUtility from "../../libs/PagePathUtility";

const footer = ({ navigationItems }) => {
  /**
   * Render menu recursive
   * @param items
   * @param itemClass
   * @returns {XML}
   */
  const renderSubPages = (items, itemClass) => {
    return (
      <>
        <ul className={itemClass}>
          {Object.values(items).map((menuItem, i) => {
            if (!menuItem.nextLinkAs) return null;
            return (
              <li key={i}>
                {/*Internal links*/}
                {menuItem.nextLinkHref &&
                  !menuItem.nextLinkAs.startsWith("http") && (
                    <Link
                      href={menuItem.nextLinkAs.replace(/\/$/, "")}
                      prefetch={false}
                    >
                      <a className="footer__link hover-effect-border hover-effect-border--white">
                        {menuItem.title}
                      </a>
                    </Link>
                  )}
                {/*External links*/}
                {menuItem.nextLinkHref &&
                  menuItem.nextLinkAs.startsWith("http") && (
                    <a
                      className="footer__link external hover-effect-border hover-effect-border--white"
                      target={menuItem.target}
                      href={menuItem.nextLinkAs}
                    >
                      {menuItem.title}
                    </a>
                  )}
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <footer className="footer">
      <div className="max-content">
        <div className="grid-x grid-margin-x footer__nav">
          {navigationItems &&
            navigationItems.map((menuItem, index) => {
              if (!menuItem.nextLinkAs) return null;
              return (
                <div key={index} className="cell small-12 medium-3 large-2">
                  <h4 className="nav_headline">{menuItem.title}</h4>
                  {menuItem.children &&
                    renderSubPages(menuItem.children, "footer__list no-list")}
                </div>
              );
            })}
        </div>
        <div className="footer__meta">
          <p className="footer__copyright">
            Copyright © Wolters Kluwer Deutschland GmbH
          </p>
          <img
            className="footer__wolters"
            src={PagePathUtility.createLinkWithBasepath(
              "/static/img/logo_lto_wolters_kluwer.png"
            )}
            width={185}
            height={30}
            alt="logo lto Wolters-Kluwer"
          />
        </div>
      </div>
    </footer>
  );
};

export default footer;
